import React, {
  ChangeEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import { config } from "../../config/config";

import { useHttp } from "../../hooks/http.hook";

import { useHistory, useParams } from "react-router-dom";
import { IOrder } from "./types";

// import { IColor } from './types'

const DetailPage: React.FC = () => {
  const { id } =
    useParams<{
      id: string;
    }>();

  const { token } = useContext(Contexts.AuthContext);

  const history = useHistory();
  console.log("history.location.pathname", history.location.pathname);

  const { loading, request } = useHttp();

  let oneOrder: any;
  let address: any = ["", ""];
  let prefix: string = "";

  const [order, setOrder] = useState<IOrder[]>([]);
  const [typesOfPayment, setTypesOfPayment] = useState<any[]>([]);
  const [typeOfPayment, setTypeOfPayment] = useState<string>("");
  const [form, setForm] = useState<string>("");

  // const [colors, setColors] = useState<IColor[]>([])
  // const [isCreate, toggleCreate] = useState<boolean>(false)

  const Events = {
    /* addHandler: () => {
            toggleCreate(!isCreate)
        }, */
    changeHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      // console.log(e.target.value);
      setForm(e.target.value);
    },
    cancelHandler: () => {
      const response = window.confirm(
        "Вы действительно хотите отменить заказ?"
      );
      if (response) Callbacks.Cancel();
    },
    saveHandler: () => {
      Callbacks.SaveData();
    },
  };

  const Callbacks = {
    FetchOrder: async () => {
      try {
        /* const response: any = await request(
                    `${config.API}/colors`,
                    'GET', null, {
                        Authorization: token
                    })

                if (response.data) setColors(response.data) */
        const response: any = await request(
          `${config.ordersAPI}/${id}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        if (!response.order) {
          history.push("/perm_error");
        }

        // console.log(response.order[0].typeOfPayment);
        setTypesOfPayment([
          { _id: "online", title: "Карта" },
          { _id: "cash", title: "Наличные" },
        ]);
        setTypeOfPayment(response.order[0].typeOfPayment);

        // oneOrder = ordersDummy.find(element => element._id === id.toString());
        if (response.order[0].status === "searchDriver") {
          response.order[0].status = "Поиск водителя";
        } else if (response.order[0].status === "accepted") {
          response.order[0].status = "Подтвержден";
        } else if (response.order[0].status === "driverInGoing") {
          response.order[0].status = "Водитель в пути";
        } else if (response.order[0].status === "inProgress") {
          response.order[0].status = "В процессе";
        } else if (response.order[0].status === "waiting_for_payment") {
          response.order[0].status = "Ожидает оплаты";
        } else if (response.order[0].status === "done") {
          response.order[0].status = "Выполнен";
        } else if (response.order[0].status === "canceledByClient") {
          response.order[0].status = "Отменен клиентом";
        } else if (response.order[0].status === "canceledByAdmin") {
          response.order[0].status = "Отменен администратором";
        } else if (response.order[0].status === "expiredByTime") {
          response.order[0].status = "Истек";
        }

        if (response.order[0].typeOfPayment === "online") {
          response.order[0].typeOfPayment = "Карта";
        } else {
          response.order[0].typeOfPayment = "Наличные";
        }

        address[0] = response.order[0].coordinates[0].displayTitle;

        response.order[0].coordinates.forEach((item: any, key: number) => {
          prefix = key > 1 ? "->" : "";
          address[1] += key > 0 ? prefix + item.displayTitle : "";
        });
        response.order[0].distance = address;
        setOrder(response.order);
        // console.log(id, oneOrder);
      } catch (e) {
        console.log(e);
      }
    },
    SaveData: async () => {
      try {
        await request(
          `${config.ordersAPI}/${id}/typeOfPayment`,
          "POST",
          { type: form ? form : typeOfPayment },
          {
            Authorization: token,
          }
        );
        Callbacks.FetchOrder();
        // await window.location.reload(true)
      } catch (e) {
        console.log(e);
      }
    },
    Cancel: async () => {
      try {
        return await request(`${config.ordersAPI}/${id}/cancel`, "POST", null, {
          Authorization: token,
        });
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchOrder();
  }, []);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <Fragment>
      <Page.BackLink title="Вернуться к заказам" linkTo="/orders" />

      <div className="page-wrapper">
        <Components.DetailBlock
          data={order}
          typesOfPayment={typesOfPayment}
          typeOfPayment={typeOfPayment}
          // fineHandler={Events.fineHandler}
          // blockHandler={Events.blockHandler}
          // deleteHandler={Events.deleteHandler}
          changeHandler={Events.changeHandler}
          cancelHandler={Events.cancelHandler}
          saveHandler={Events.saveHandler}
        />
      </div>
    </Fragment>
  );
};

export default DetailPage;
