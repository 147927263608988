import React from 'react'

import { IDetailFieldProps } from './types'
import {Link} from "react-router-dom";


const DetailField: React.FC<IDetailFieldProps> = ({
  type,
  label,
  value,
  link,
  fontWeight = 'bold'
}) => {

  if (type === 'stars') {
    return (
      <div className='detail-field'>
        <img
          alt='Stars'
          src={require('../.././assets/star.png')}
        />

        <p className={`detail-field-value detail-field-value--${fontWeight}`}>
          {value ? value : '-'}
        </p>
      </div>
    )
  }

  if (type === 'filter') {
    return (
      <div className='detail-field detail-field--filter'>
        <h4 className='detail-field-label detail-field-label--filter'>
          {label}
        </h4>

        <p className={`detail-field-value detail-field-value--${fontWeight}`}>
          {value ? value : '-'}
        </p>
      </div>
    )
  }

    if (type === 'link') {
      console.log("link",link);

      return (
            <div className='detail-field detail-field--filter'>
                <h4 className='detail-field-label detail-field-label--filter'>
                    {label}
                </h4>

                <p className={`detail-field-value detail-field-value--${fontWeight}`}>
                    <Link to={ `${link}` }>{value ? value : '-'}</Link>
                </p>
            </div>
        )
    }

    if (type === 'unlink') {
        return (
            <div className='detail-field detail-field--filter'>
                <h4 className='detail-field-label detail-field-label--filter'>
                    {label}
                </h4>

                <p className={`detail-field-value detail-field-value--${fontWeight}`}>
                    <span>{value ? value : '-'}</span>
                </p>
            </div>
        )
    }

    if (type === 'distance') {
        return (
            <div className='detail-field-dist detail-field-dist--distance'>
                <div className='detail-field-dist-label detail-field-dist-label--distance'>
                    {label}

                    <b className="detail-field-dist-value">{value ? value : '-'}</b>
                </div>
            </div>
        )
    }

  return (
    <div className='detail-field'>
      <h4 className='detail-field-label'>
        {label}
      </h4>

      <p className={`detail-field-value detail-field-value--${fontWeight}`}>
        {value ? value : '-'}
      </p>
    </div>
  )
}

export default DetailField
