import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { ICompany } from './types'

import { config } from '../../config/config'

import * as CSV from '../../components/ExcelUpload'

import { CompaniesDummy } from './data' // waiting for API


const IndexPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [companies, setCompanies] = useState<ICompany[]>([])
  const [xslsData, setXSLSData] = useState<any>()
  const [isCreate, toggleCreate] = useState<boolean>(false)

  const Events = {
    addHanlder: () => {
      toggleCreate(!isCreate)
    }
  }

  const Callbacks = {
    FetchCompanies: async () => {
      try {
         const response: any = await request(
             `${config.API}/movers`,
             'GET', null, {
             Authorization: token
         })

          const responseSXLS: any = await request(
              `${config.API}/movers`,
              'GET', null, {
                  Authorization: token
              })

          const Arr: any = [];

          responseSXLS.data.forEach((resp: any, key1: any) => {
              Arr.push({
                name: resp.title,
                phone: resp.phone,
                description: resp.description,
              });
          });

          setXSLSData(Arr)

         setCompanies(response.data)
        // setCompanies(CompaniesDummy)

      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.FetchCompanies()
  }, [])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }

  return (
    <div className='page-wrapper'>

      <Components.Header
        addHandler={Events.addHanlder}
      />
        <div style={{ marginLeft: '40px', marginTop: '-100px', marginBottom: '40px' }}><CSV.ExcelUpload csvData={xslsData} fileName={'movers'}/></div>

      <Components.Table
        items={companies}
        isCreate={isCreate}
      />

    </div>
  )
}



export default IndexPage
