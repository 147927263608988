import React, { useState, useContext, useEffect } from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks/http.hook";

import { config } from "../../config/config";

import { IPagination, IFilter } from "../../types";
import { IStatistics } from "./types";
import { useHistory } from "react-router-dom";

import { StatisticsDummy } from "./data"; // waiting for API

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [statistics, setStatistics] = useState<IStatistics[]>([]);
  const [xslsData, setXSLSData] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "date",
    sortOrder: "asc",
    fullName: "",
    phone: "",
    dateUse: false,
    dateFrom: "",
    dateTo: "",
  });
  const history = useHistory();

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
      console.log(filter);
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1];
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.FetchStatistics(1);
    },
    timeHandler: () => {
      setFilter({ ...filter, dateUse: !filter.dateUse });
    },
    searchHandler: () => {
      Callbacks.FetchStatistics(1);
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
  };

  const Callbacks = {
    FetchStatistics: async (page?: number) => {
      try {
        const response: any = await request(
          `${config.API}/statistics?page=${
            page ? page : pagination.page
          }&perPage=${pagination.perPage}&sortOrder=${
            filter.sortOrder
          }&sortBy=${filter.sortBy}&name=${filter.fullName}&createdAt[]=${
            filter.dateUse ? filter.dateFrom : ""
          }&createdAt[]=${filter.dateUse ? filter.dateTo : ""}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        const responseSXLS: any = await request(
          `${config.API}/statistics?page=${1}&perPage=${
            response?.pagination?.total
          }&createdAt[]=${filter.dateUse ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateTo
          }&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];

        if (!response.data) {
          history.push("/perm_error");
        }

        responseSXLS.data.forEach((resp: any) => {
          Arr.push({
            appPerDay: resp.app_per_day,
            manager: resp.name,
            date: new Date(resp.createdAt).toLocaleDateString(),
          });
        });
        Arr.push({
          count: responseSXLS.allStatisticsCount,
        });

        setXSLSData(Arr);
        setStatistics(response.data);

        if (response.pagination)
          setPagination({
            ...pagination,
            totalCount: response.pagination.total,
            page: response.pagination.page,
          });
        else setPagination({ ...pagination, totalCount: 0 });
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchStatistics();
  }, [
    /*filter.dateFrom, filter.dateTo,*/ filter.sortBy,
    filter.dateUse,
    filter.sortOrder,
    pagination.page,
  ]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <div className="page-wrapper">
      <Page.Filter
        agePicker
        registerDateSelect
        fullNameInput
        form={filter}
        events={Events}
      >
        <h2 className="filter-bar-title filter-bar-title--bold">
          Статистика менеджеров компании
        </h2>

        <CSV.ExcelUpload csvData={xslsData} fileName={"statistics"} />
      </Page.Filter>

      <Components.Table items={statistics} />

      <Page.Pagination
        linkTo="/statistics"
        pagination={pagination}
        paginateHandler={Events.paginateHandler}
      />
    </div>
  );
};

export default IndexPage;
