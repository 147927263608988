import React, { useContext, useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";

import * as Contexts from "../../contexts";
import * as Table from "../../components/Table";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Components from "./components";

import { useHttp } from "../../hooks/http.hook";

import { config } from "../../config/config";

import { IFilter, IPagination } from "../../types";
import { IConsumer } from "./types";
import { IOrder } from "../Orders/types";

import { ordersDummy } from "../Orders/data"; // waiting for API

const DetailPage: React.FC = () => {
  const { id } = useParams();

  const { token } = useContext(Contexts.AuthContext);

  const history = useHistory();

  const { loading, request } = useHttp();

  const [consumer, setConsumer] = useState<IConsumer | null>(null);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 5,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "orderTime",
    sortOrder: "asc",
    orderNumber: "",
  });

  let address: any = [];
  let prefix: string = "";

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1];
      setFilter({ ...filter, sortBy, sortOrder });
      Callbacks.FetchOrders();
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        Callbacks.FetchConsumer();
        Callbacks.FetchOrders();
      }
    },
    searchHandler: () => {
      Callbacks.FetchOrders();
    },
    deleteHandler: () => {
      const answer = window.confirm("Delete?");
      if (answer) Callbacks.Delete();
    },
    unblockHandler: () => {
      const response = window.confirm("Unblock?");
      if (response) Callbacks.UnBlock();
    },
    blockHandler: () => {
      const answer = window.confirm("Block?");
      if (answer) Callbacks.Block();
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
  };
  console.log("history.location.pathname", history.location.pathname);

  const Callbacks = {
    FetchConsumer: async () => {
      try {
        const response: any = await request(
          `${config.API}/clients/${id}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        if (!response.data) {
          history.push("/perm_error");
        }

        setConsumer(response.data);
      } catch (e) {
        console.log(e);
      }
    },
    FetchOrders: async (page?: number) => {
      try {
        const response: any = await request(
          `${config.API}/clients/${id}/byClient/?page=${
            page ? page : pagination.page
          }&perPage=${pagination.perPage}&sortOrder=${
            filter.sortOrder
          }&sortBy=${filter.sortBy}&_index=${filter.orderNumber}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        if (response.data) {
          response.data.forEach((item: any, key: number) => {
            item.time =
              new Date(item.time).toLocaleTimeString() +
              " " +
              new Date(item.time).toLocaleDateString();
            address[key] = "";
            if (item.status === "searchDriver") {
              item.status = "Поиск водителя";
            } else if (item.status === "accepted") {
              item.status = "Подтвержден";
            } else if (item.status === "driverInGoing") {
              item.status = "Водитель в пути";
            } else if (item.status === "inProgress") {
              item.status = "В процессе";
            } else if (item.status === "waiting_for_payment") {
              item.status = "Ожидает оплаты";
            } else if (item.status === "done") {
              item.status = "Выполнен";
            } else if (item.status === "canceledByClient") {
              item.status = "Отменен клиентом";
            } else if (item.status === "canceledByAdmin") {
              item.status = "Отменен администратором";
            } else if (item.status === "paymentError") {
              item.status = "Ошибка оплаты";
            } else if (item.status === "paymentProcessing") {
              item.status = "В процессе оплаты";
            } else if (item.status === "expiredByTime") {
              item.status = "Истек";
            }

            if (item.typeOfPayment === "online") {
              item.typeOfPayment = "Карта";
            } else {
              item.typeOfPayment = "Наличные";
            }

            item.coordinates.forEach((item1: any, key1: number) => {
              prefix = key1 > 0 ? "->" : "";
              address[key] += prefix + item1.displayTitle;
            });
            item.distance = address[key];
          });
          // setOrders(ordersDummy)
          setOrders(response.data);

          if (response.pagination)
            setPagination({
              ...pagination,
              totalCount: response.pagination.total,
              page: response.pagination.page,
            });
          else setPagination({ ...pagination, totalCount: 0 });
        }
      } catch (e) {
        console.log(e);
      }
    },
    Delete: async () => {
      try {
        console.log(orders);

        if (!orders.length) {
          await request(`${config.API}/clients/${id}`, "DELETE", null, {
            Authorization: token,
          });
          history.push("/consumers");
        } else {
          window.alert("Невозможно удалить профиль");
        }
      } catch (e) {
        console.log(e);
      }
    },
    UnBlock: async () => {
      try {
        await request(`${config.API}/clients/unblock/${id}`, "POST", null, {
          Authorization: token,
        });
        await Callbacks.FetchConsumer();
      } catch (e) {
        console.log(e);
      }
    },
    Block: async () => {
      try {
        await request(`${config.API}/clients/ban/${id}`, "POST", null, {
          Authorization: token,
        });
        await Callbacks.FetchConsumer();
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchConsumer();
    Callbacks.FetchOrders();
  }, [filter.sortBy, filter.sortOrder, pagination.page]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <Fragment>
      <Page.BackLink title="Вернуться к списку клиентов" linkTo="/consumers" />

      <div className="page-wrapper">
        <Components.DetailBlock
          data={consumer}
          deleteHandler={Events.deleteHandler}
          blockHandler={
            consumer?.status !== "blocked"
              ? Events.blockHandler
              : Events.unblockHandler
          }
        />

        <Page.Filter
          dateTimeSelect
          statusSelect
          tariffSelect
          orderInput
          form={filter}
          events={Events}
        >
          <Form.DetailField
            type="filter"
            label="Заказов"
            value={`${pagination.totalCount}`}
          />
        </Page.Filter>

        {orders[0] ? (
          <div>
            <Table.OrdersTable items={orders} padding="bottom" />{" "}
            <Page.Pagination
              linkTo={`/consumers/${id}`}
              pagination={pagination}
              paginateHandler={Events.paginateHandler}
            />{" "}
          </div>
        ) : (
          <div className="havenot-order">У этого клиента нет заказов!</div>
        )}

        <Page.Footer data={consumer?.orderSum} />
      </div>
    </Fragment>
  );
};

export default DetailPage;
