import React, {useContext, useState} from 'react'

import * as Form from '../../../components/Form'
import * as Buttons from '../../../components/Buttons'

import { useHttp } from '../../../hooks/http.hook'

import { IDetailBlockProps } from './types'
import {useParams} from "react-router-dom";
import {config} from "../../../config/config";
import * as Contexts from "../../../contexts";
import * as Components from '../components'
import {TModalContent} from "../../Drivers/types";


const DetailBlock: React.FC<IDetailBlockProps> = ({
  data,
  deleteHandler,
  blockHandler,
  unblockHandler
}) => {

  const { id } = useParams();

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [modal, setModal] = useState<TModalContent>(null)

  const Events = {
    showModal: () => {
      setModal('driver')
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    fireHandler: (driverId: string) => {
      const response = window.confirm('Fire?')
      if (response) Callbacks.Fire(driverId)
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  };

  const Callbacks = {
    Fire: async (driverId: string) => {
      try {
        const response : any = await request(`${config.API}/users/${id}/deleteDriver/${driverId}`, 'DELETE', null, {
          Authorization: token
         })

        if (response) {
          await window.location.reload()
        }
      } catch (e) {
        console.log(e)
      }
    }
  };


  return (
    <div className='page-header consumers-header'>

      <Components.Modal
          type={modal}
          data={data}
          clearModal={Events.clearModal}
      />

      <div className='consumers-header-part'>
        <img
          alt='logo'
          src="http://placehold.it/140"
        />

        <div className='consumers-header-column'>
          <Form.DetailField
            label='Название компании:'
            value={data?.title}
          />

          <Form.DetailField
            label='Логин:'
            value={data?.loginData?.login}
          />
        </div>

        <div className='consumers-header-column'>
          <Form.MultiDataField
              label='Водители:'
              type='link'
              link={'/drivers/'}
              values={
                (data?.drivers) ? data?.drivers : []
              }
              buttonHandler={Events.fireHandler}
          />
          <Buttons.Small
              title='Добавить'
              type='ok'
             // buttonType='plus'
              buttonHandler={Events.showModal}
          />
        </div>

        <div className='consumers-header-column'>
          <Form.DetailField
            label='Описание:'
            value={data?.description}
          />
        </div>

      </div>

      <div className='consumers-header-part'>

        <div className='consumers-header-column'>
          <Buttons.Default
            title='Удалить профиль'
            type='button'
            buttonType='delete'
            buttonHandler={deleteHandler}
          />

          {(data?.status === 'Active') ? <Buttons.Default
            title='Заблокировать'
            type='button'
            buttonType='delete'
            buttonHandler={blockHandler}
          /> : <Buttons.Default
              title='Разблокировать'
              type='button'
              buttonType='green'
              buttonHandler={unblockHandler}
          />}
        </div>
      </div>

    </div>
  )
}
export default DetailBlock
