import React, { useContext, useState, Fragment } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { useHttp } from '../../../../hooks/http.hook'

import { config } from '../../../../config/config'

import { IRejectModalProps } from './types'
import { useHistory } from 'react-router-dom'



const RejectModal: React.FC<IRejectModalProps> = ({
  data,
  clearModal
}) => {

  const { token } = useContext(Contexts.AuthContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [reason, setReason] = useState<string>('')

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setReason(e.target.value)
    },
    cancelHandler: () => {
      clearModal()
    },
    rejectHandler: () => {
      Callbacks.Reject()
      clearModal()
      history.goBack()
    }
  }

  const Callbacks = {
    Reject: async () => {
      try {
        await request(`${config.API}/cars/${data?._id!}`, 'DELETE', null, {
          Authorization: token
        })
      } catch (e) {
        console.log(e)
      }
    }
  }


  return (
    <Fragment>

      <h4 className='page-modal-title'>
        Отклонить документы
      </h4>

      <Form.Textarea
        type='large'
        label='Укажите причину:'
        name='reason'
        placeholder='Например: Фотография прав слишком низкого качества'
        value={reason}
        inputHandler={Events.inputHandler}
      />

      <div className='page-modal-btns'>
        <Buttons.Large
          type='cancel'
          title='Отмена'
          buttonHandler={Events.cancelHandler}
        />

        <Buttons.Large
          type='ok'
          title='Отклонить'
          buttonHandler={Events.rejectHandler}
        />
      </div>

    </Fragment>
  )
}
export default RejectModal