import { useMesasge } from './message.hook';
import { useState, useCallback, useContext } from "react"
import * as Contexts from '../contexts'



interface IRequestHookProps {
    (
        url: string,
        method: string,
        body: string | object | null,
        headers?: {
            Authorization?: string | null
            ['Content-Type']?: string
        },
        needStatus?: boolean | null,
    ): Promise<any>
}

interface IFetch {
    (
        url: string,
        params: {
            method: string
            body: object | null,
            headers: {
                ['Content-Type']?: string
            },
        }
    ): any
}


export const useHttp = () => {

    const { logout } = useContext(Contexts.AuthContext)
    const message = useMesasge()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const request = useCallback<IRequestHookProps>(
        async (url, method = 'GET', body = null, headers = {}, needStatus = false) => {

            setLoading(true)

            try {
                if (body instanceof FormData) {
                } else if (body && !headers['Content-Type']) {
                    body = JSON.stringify(body)
                    headers['Content-Type'] = 'application/json'
                }

                //@ts-ignore
                const response: any = await fetch<IFetch>(url, {
                    method, body, headers
                })

                let data: any = await response.json()

                if (needStatus) {
                    data = await response;
                }

                if (response.status === 401) {
                    logout()
                    setError(data.message)
                    setLoading(false)
                    return false
                }

                if (!response.ok && !needStatus) {
                    message(data.message)
                    setLoading(false)
                    throw new Error(data.message || 'Smth was wrong')
                }

                setLoading(false)
             //   console.log(data)
                return data

            } catch (e) { }

        }, [message]
    )

    const clearError = useCallback(() => setError(null), [])

    return { loading, error, request, clearError }
}

