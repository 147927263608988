import React, { Fragment } from 'react'

import * as Page from '../../../components/Page'

import FineModal from './Detail/FineModal'
import RejectModal from './Docs/RejectModal'

import { IModalProps } from './types'
import { IDriver, IDriverCar } from '../types'
import DeactivateModal from './Docs/DeactivateModal'


const Modal: React.FC<IModalProps> = ({
  type,
  data,
  images = [],
  clearModal
}) => {

  const Events = {
    contentHandler: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
    }
  }

  return (
    <Fragment>
      <Page.Modal
        isActive={type}
        clearModal={clearModal}
      >
        <div
          className={`page-modal-container page-modal-container--${type}`}
          onClick={Events.contentHandler}
        >

          {type === 'fine' ? (
            <FineModal
              data={(data as IDriver)}
              clearModal={clearModal}
            />
          ) : null}

          {type === 'deactivate' ? (
            <DeactivateModal
              data={(data as IDriverCar)}
              clearModal={clearModal}
            />
          ) : null}

          {type === 'reject' ? (
            <RejectModal
              data={(data as IDriverCar)}
              clearModal={clearModal}
            />
          ) : null}

          {type === 'slider' ? (
            <Page.Slider
              images={images}
            />
          ) : null}

        </div>
      </Page.Modal>
    </Fragment>
  )
}

export default Modal
