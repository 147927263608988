import React, { useState } from 'react'

import { IInputToggleProps } from './types'

import Edit from '../../assets/edit.png'
import Check from '../../assets/check.png'


const InputToggle: React.FC<IInputToggleProps> = ({
  name,
  type = 'number',
  isCorrect = true,
  label,
  prefix,
  suffix,
  value,
  changeHandler = () => { }
}) => {

  const [isActive, toggleActive] = useState<boolean>(false)

  const Events = {
    activeHandler: () => {
      if (isCorrect) toggleActive(!isActive)
    }
  }

  return (
    <div className='input-toggle'>

      {label ? (
        <h6 className='input-toggle-label'>
          {label}
        </h6>
      ) : null}

      <div className='input-toggle-wrapper'>

        {prefix ? (
          <span>{prefix}</span>
        ) : null}

        <input
          name={name}
          type={type}
          className={`
            input-show 
            ${isActive && 'input-show--change'}
            input-show--${type}
          `}
          disabled={!isActive}
          value={value}
          onChange={changeHandler}
        />

        {suffix ? (
          <span>{suffix}</span>
        ) : null}

      </div>

      <img
        className='btn-table-edit'
        alt={!isActive ? 'Edit' : 'Submit'}
        src={!isActive ? Edit : Check}
        onClick={Events.activeHandler}
      />

    </div>
  )
}


export default InputToggle
