import React from 'react'

import * as Form from '../Form'

import { IFooterProps } from './types'
import { Link } from 'react-router-dom'


const DetailFooter: React.FC<IFooterProps> = ({
  linkTitle,
  linkTo = '/',
  data
}) => {

  return (
    <footer className='page-footer'>

      <div className='page-footer-block'>
        {data?.balance ? (
          <Form.DetailField
            label='Картой:'
            value={`${data?.balance} грн`}
          />
        ) : null}

        {linkTitle && !Array.isArray(linkTitle) ? (
          <Link to={linkTo}>
            {linkTitle}
          </Link>
        ) : null}

          {Array.isArray(linkTitle) ?
              linkTitle.map((res: any, key: any) =>
                  <Link key={key} className="footer-text-bottom" to={linkTo[key]}>
                      {res}
                  </Link>
              )
           : null}
      </div>

      <div className='page-footer-block'>
        {data?.online ? (
          <Form.DetailField
            label='Картой:'
            value={`${data?.online} грн`}
          />
        ) : null}

        {data?.cash ? (
          <Form.DetailField
            label='Наличными:'
            value={`${data?.cash} грн`}
          />
        ) : null}

        {data?.total ? (
          <Form.DetailField
            label='Общая сумма заказов:'
            value={`${data?.total} грн`}
          />
        ) : null}

        {data?.ordersSum ? (
          <Form.DetailField
            label='Общая сумма заказов:'
            value={`${data?.ordersSum} грн`}
          />
        ) : null}

      </div>

    </footer>
  )
}

export default DetailFooter
