import React, { useContext } from "react";

import * as Table from "../../../../components/Table";

import { useHttp } from "../../../../hooks/http.hook";
import { useMesasge } from "../../../../hooks/message.hook";

import { ITrackProps } from "./types";
import { config } from "../../../../config/config";
import * as Contexts from "../../../../contexts";

const Track: React.FC<ITrackProps> = ({ type, data }) => {
  const { token } = useContext(Contexts.AuthContext);

  const { request } = useHttp();

  const message = useMesasge();

  const Events = {
    copyHandler: () => {
      const str = data.card;
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    withdrawHandler: () => {
      Callbacks.PayMoney();
    },
  };

  const Callbacks = {
    PayMoney: async () => {
      try {
        const responce: any = await request(
          `${config.ordersAPI}/payment/${data._id}/pay`,
          "POST",
          null,
          {
            Authorization: token,
          }
        );

        if (data.balance >= data.sum) {
          if (responce) {
            message("Деньги выведены!");
          } else {
            message("Транзакция не успешна!");
          }
        } else {
          message("Баланс водителя меньше запрашиваемой суммы!");
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  const renderRightIcon = () => {
    return (
      <img
        alt="copy"
        src={require("../../../../assets/copy.png")}
        onClick={Events.copyHandler}
      />
    );
  };

  return (
    <div className={`table-track table-track--${type}`}>
      <Table.Cell
        textWeight="bold"
        text={new Date(data.createdAt).toLocaleDateString()}
      />

      <Table.Cell textWeight="bold" text={data.fullName} />

      <Table.Cell textWeight="bold" text={data.phone} />

      <Table.Cell textWeight="bold" text={`${data.balance.toFixed(2)} грн`} />

      <Table.Cell text={`${data.sum} грн`} />

      <Table.Cell
        textWeight="bold"
        text={`${data.card}`}
        renderRightIcon={renderRightIcon}
      />

      <Table.Cell
        type="button"
        buttonTitle="Вывести"
        buttonType="default"
        buttonHandler={Events.withdrawHandler}
      />
    </div>
  );
};

export default Track;
