import React, {useContext, useState, useEffect, Fragment, ChangeEvent} from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import { config } from '../../config/config'
import {IDriver, IFine, TModalContent} from "./types";
import {IOrder} from "../Orders/types";
import {IFilter, IPagination} from "../../types";
import * as Form from "../../components/Form";
import * as Table from "../../components/Table";
// import {OpirateDummy} from "./data";

const FinePage: React.FC = () => {

    const { id } = useParams()

    const { token } = useContext(Contexts.AuthContext)

    const { loading, request } = useHttp()

    const history = useHistory();

    const [driver, setDriver] = useState<IDriver | null>(null)
    const [fines, setFines] = useState<IFine[]>([])
    const [modal, setModal] = useState<TModalContent>(null)
    const [filter, setFilter] = useState<IFilter>({
        sortBy: 'date',
        sortOrder: 'asc',
        orderNumber: '',
    })

    const Events = {
        inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
            setFilter({ ...filter, [e.target.name]: e.target.value })
        },
        selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
            const sortBy = e.target.value.split('-')[0]
            const sortOrder = e.target.value.split('-')[1]
            setFilter({ ...filter, sortBy, sortOrder })
        },
        pressHandler: (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') Callbacks.FetchFines()
        },
        searchHandler: () => {
            Callbacks.FetchFines()
        },
        fineHandler: () => {
            setModal('fine')
            const body = document.querySelector('body')
            if (body) body.style.overflow = 'hidden'
        },
        deleteHandler: () => {
            const response = window.confirm('Delete?')
            if (response) Callbacks.Delete()
        },
        unblockHandler: () => {
            const response = window.confirm('Unblock?');
            if (response) Callbacks.UnBlock()
        },
        blockHandler: () => {
            const response = window.confirm('Block?')
            if (response) Callbacks.Block()
        },
        clearModal: () => {
            setModal(null)
            const body = document.querySelector('body')
            if (body) body.style.overflow = 'auto'
        }
    }

    const Callbacks = {
        FetchDriver: async () => {
            try {
                const response: any = await request(
                    `${config.API}/drivers/${id}`, 'GET', null, {
                        Authorization: token
                    })

                setDriver(response.data)
            } catch (e) {
                console.log(e)
            }
        },
        FetchFines: async () => {
            try {
                 const response: any = await request(
                   `${config.ordersAPI}/drivers/${id}/fines`, 'GET', null, {
                   Authorization: token
                 })

                setFines(response.data)
            } catch (e) {
                console.log(e)
            }
        },
        UnBlock: async () => {
            try {
                await request(
                    `${config.API}/drivers/unblock/${id}`, 'POST', null, {
                        Authorization: token
                    })
                await Callbacks.FetchDriver()
            } catch (e) {
                console.log(e)
            }
        },
        Block: async () => {
            try {
                await request(
                    `${config.API}/drivers/ban/${id}`, 'POST', null, {
                        Authorization: token
                    })
                await Callbacks.FetchDriver()
            } catch (e) {
                console.log(e)
            }
        },
        Delete: async () => {
            try {
                await request(
                    `${config.API}/drivers/${id}`, 'DELETE', null, {
                        Authorization: token
                    })
                history.push('/drivers');
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.FetchDriver()
        Callbacks.FetchFines()
    }, [])


    if (loading) {
        return (
            <Page.Preloader />
        )
    }


    return (
        <Fragment>

            <Components.Modal
                type={modal}
                data={driver}
                clearModal={Events.clearModal}
            />

            <Page.BackLink
                title='Вернуться к профилю водителя'
                linkTo={`/drivers/${id}`}
            />

            <div className='page-wrapper'>
                <Components.DetailBlock
                    data={driver}
                    fineHandler={Events.fineHandler}
                    blockHandler={driver?.status !== 'blocked' ? Events.blockHandler : Events.unblockHandler}
                    deleteHandler={Events.deleteHandler}
                />

                <Page.Filter
                    dateSelect
                    statusSelect
                    actionSelect
                    form={filter}
                    events={Events}
                >

                    <Form.DetailField
                        type='filter'
                        label='Операций'
                        value={`${fines.length}`}
                    />

                </Page.Filter>
                { (fines[0]) ?
                <Components.FineTable
                    items={fines}
                /> : <div className="havenot-order">У этого водителя нету штрафов!</div> }

                <Page.Footer
                    data={driver?.ordersSum}
                />

            </div>
        </Fragment>
    )
}

export default FinePage
