import React, { useContext, useState, Fragment } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { useHttp } from '../../../../hooks/http.hook'

import { config } from '../../../../config/config'

import { useMesasge } from "../../../../hooks/message.hook";

import { IFineModalProps, IForm } from './types'
import {useParams} from "react-router-dom";
import {isNumber} from "util";



const FineModal: React.FC<IFineModalProps> = ({
  data,
  clearModal
}) => {


  const { id } = useParams()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const message = useMesasge();

  const [form, setForm] = useState<IForm>({
    sum: 0,
    reason: ''
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // @ts-ignore
      if ((e.target.name === 'sum' && +e.target.value <= 0)  || (e.target.name === 'sum' && !+e.target.value.match(/^\d+/))) {
        // @ts-ignore
        e.target.value = 0
      }
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    cancelHandler: () => {
      clearModal()
    },
    fineHandler: async () => {
       const responce = await request(
            `${config.ordersAPI}/drivers/${id}/fine`, 'POST', {
                sum: (form.sum > 0) ? +form.sum : '',
                reason: form.reason
           }, {
                Authorization: token
            })

       if (responce) {
        clearModal();
       } else {
        message('Не все поля заполнены!');
       }
    }
  }


  return (
    <Fragment>

      <h4 className='page-modal-title'>
        Назначить штраф водителю
      </h4>

      <Form.Input
        type='number'
        label='Сумма штрафа (грн):'
        margin='bottom'
        name='sum'
        value={form.sum}
        inputHandler={Events.inputHandler}
      />

      <Form.Textarea
        label='Укажите причину:'
        name='reason'
        value={form.reason}
        inputHandler={Events.inputHandler}
      />

      <div className='page-modal-btns'>
        <Buttons.Large
          type='cancel'
          title='Отмена'
          buttonHandler={Events.cancelHandler}
        />

        <Buttons.Large
          type='ok'
          title='Оштрафовать'
          buttonHandler={Events.fineHandler}
        />
      </div>

    </Fragment>
  )
}

export default FineModal
