import React from 'react'

import Header from './Header'

import { ITableProps } from './types'



const Table: React.FC<ITableProps> = ({
  padding,
  header,
  children,
  sortHandler
}) => {

  return (
    <div className={`table table--padding-${padding}`}>
      <Header
        cells={header.cells}
        type={header.type}
        sortHandler={sortHandler}
      />

      {children}
    </div>
  )
}

export default Table