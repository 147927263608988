import { IOption } from "./types";

export const actionOptions: IOption[] = [
  { title: "Action asc", value: "action-asc" },
  { title: "Action desc", value: "action-desc" },
];

export const dateOptions: IOption[] = [
  { title: "Date asc", value: "createdAt-asc" },
  { title: "Date desc", value: "createdAt-desc" },
];

export const orderDateTimeOptions: IOption[] = [
  { title: "Date asc", value: "orderTime-asc" },
  { title: "Date desc", value: "orderTime-desc" },
];

export const registerOptions: IOption[] = [
  { title: "Дата регистрации по возрастанию", value: "createdAt-asc" },
  { title: "Дата регистрации по спаданию", value: "createdAt-desc" },
];

export const fullNameOptions: IOption[] = [
  { title: "Имя А-Я", value: "fullName-asc" },
  { title: "Имя Я-А", value: "fullName-desc" },
];

// don't know how it will be
export const carsOptions: IOption[] = [
  { title: "Cars asc", value: "title-asc" },
  { title: "Cars desc", value: "title-desc" },
];

export const statusOptions: IOption[] = [
  { title: "Status asc", value: "status-asc" },
  { title: "Status desc", value: "status-desc" },
];

export const balanceOptions: IOption[] = [
  { title: "Баланс по возрастанию", value: "balance-asc" },
  { title: "Баланс по спаданию", value: "balance-desc" },
];

export const sumOptions: IOption[] = [
  { title: "Сумма оплаты по возрастанию", value: "sum-asc" },
  { title: "Сумма оплаты по спаданию", value: "sum-desc" },
];

export const ordersStatusOptions: IOption[] = [
  { title: "Все", value: "" },
  { title: "Выполняется", value: "inProgress" },
  { title: "Поиск водителя", value: "searchDriver" },
  { title: "Ожидает подтверждения", value: "waiting_for_approving" },
  { title: "Отменен клиентом", value: "canceledByClient" },
  { title: "Отменен администратором", value: "canceledByAdmin" },
];

export const tariffOptions: IOption[] = [
  { title: "Tariff asc", value: "tariff-asc" },
  { title: "Tariff desc", value: "tariff-desc" },
];
