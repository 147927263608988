import React from 'react'

import { useHistory } from 'react-router-dom'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  changes,
  setChanges,
  data
}) => {
    // console.log(data.cars[0].status);

    const history = useHistory()

    const Events = {
        toProfile: () => {
            history.push(`/drivers/${data._id}`)
        },
        changeHandler: () => {
           setChanges([...changes, data._id]);
        },
        removeHandler: () => {
            const index = changes.findIndex((value: any) => value === data._id);
            if (index !== -1) {
                setChanges([
                    ...changes.slice(0, index),
                    ...changes.slice(index + 1)
                ]);
            }
        }
    }

    return (
        <div className={`table-track table-track--${type}`}>
            {(!changes.includes(data._id)) ? <Cell
                type='button'
                status={(data.cars) ? data.cars[0]?.status : undefined}
                buttonTitle='Выбрать'
                buttonType='default'
                buttonHandler={Events.changeHandler}
            /> : <Cell
                type='button'
                status={(data.cars) ? data.cars[0]?.status : undefined}
                buttonTitle='Отменить'
                buttonType='default'
                buttonHandler={Events.removeHandler}
            />}

            <Cell
                status={(data.cars) ? data.cars[0]?.status : undefined}
                textWeight='bold'
                text={data.firstName}
            />

            <Cell
                status={(data.cars) ? data.cars[0]?.status : undefined}
                text={new Date(data.createdAt).toLocaleDateString()}
            />

            <Cell
                status={(data.cars) ? data.cars[0]?.status : undefined}
                textWeight='bold'
                text={data.phone}
            />

            <Cell
                type='button'
                status={(data.cars) ? data.cars[0]?.status : undefined}
                buttonTitle='Смотреть профиль'
                buttonType='default'
                buttonHandler={Events.toProfile}
            />

        </div>
    )
}

export default Track
