import React, { createContext, useState, useEffect, useContext } from 'react'

import * as Contexts from '.'

import { useHttp } from '../hooks/http.hook'
import { config } from '../config/config'

import { IRules } from '../pages/Settings/types'
import { IUserContext } from './types'


const UserContext = createContext<IUserContext>({
  userID: null,
  login: null,
  rules: null,
  type: null,
  notif: false,
  setNotif: () => {}
})

export default UserContext


export const UserProvider: React.FC = ({ children }) => {

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [userID, setUserID] = useState<string | null>(null)
  const [login, setLogin] = useState<string | null>(null)
  const [rules, setRules] = useState<IRules | null>(null)
  const [type, setType] = useState<string | null>(null)
  const [notif, setNotif] = useState<boolean>(false)


  const Callbacks = {
    FetchUser: async () => {
      try {
        const response: any = await request(`${config.API}/profile`, 'GET', null, {
          Authorization: token
        })

        if (response) {
          setUserID(response.user._id)
          setLogin(response.user.login)
          setRules(response.user.rules)
          setType(response.user.type)
          setNotif(response.user.notif)
        }

      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (token) Callbacks.FetchUser()
  }, [token])


  return (
    <UserContext.Provider value={{
      userID, login, rules, type, notif, setNotif
    }}>
      {children}
    </UserContext.Provider>
  )
}
