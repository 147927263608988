import React, { useState, useEffect, Fragment } from 'react'

import * as Form from '../../../../components/Form'
import * as Page from '../../../../components/Page'

import { IDocBlockProps } from './types'
import Modal from '../Modal'
import { TModalContent } from '../../types'


const DocBlock: React.FC<IDocBlockProps> = ({
  data,
  title,
}) => {

  const [images, setImages] = useState<string[]>([])
  const [date, setDate] = useState<string>('')
  const [modal, setModal] = useState<TModalContent>(null)


  const Events = {
    setModalHandler: () => {
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
      setModal('slider')
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  }

  useEffect(() => {
    const arr: string[] = []

    if (title === 'Права') {
      setDate(data?.documents.rights.expiresDate!)
      arr.push(data?.documents.rights.photos.front!)
      arr.push(data?.documents.rights.photos.back!)
    } else if (title === 'Страховка') {
      setDate(data?.documents.insurance.expiresDate!)
      arr.push(data?.documents.insurance.photo!)
    } else if (title === 'Техпаспорт') {
      arr.push(data?.documents.technicalPassport.photos.one!)
      arr.push(data?.documents.technicalPassport.photos.two!)
      for (let i = 0; i < data?.documents.technicalPassport.photos.other.length!; i++) {
        arr.push(data?.documents.technicalPassport.photos.other[i]!)
      }
    }

    setImages(arr)
  }, [data?.documents.insurance.photo])

  return (
    <Fragment>
      <Modal
        type={modal}
        data={data}
        images={images}
        clearModal={Events.clearModal}
      />

      <div className='docs-block docs-block--flex'>

        <div className='docs-column'>
          <Page.Images
            images={images}
            clickHandler={Events.setModalHandler}
          />
        </div>

        <div className='docs-column'>
          <h4 className='docs-title'>
            {title}
          </h4>

          {title !== 'Техпаспорт' ? (
            <Form.DetailField
              label='Срок действия:'
              value={new Date(date!).toLocaleDateString()}
            />
          ) : null}
        </div>

      </div>
    </Fragment>
  )
}

export default DocBlock