import React, { Fragment, useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { config } from '../../config/config'

import { IFilter, IPagination } from '../../types'
import { ICar } from './types'
import { CarsDummy } from './data'


const CarsPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [cars, setCars] = useState<ICar[]>([])
  const [isCreateCar, toggleCreateCar] = useState<boolean>(false)

  const Events = {
    addHandler: () => {
      toggleCreateCar(!isCreateCar)
    },
  }

  const Callbacks = {
    FetchCars: async () => {
      try {
        const response: any = await request(
          `${config.API}/marks`,
          'GET', null, {
          Authorization: token
        })

        if (response.data) setCars(response.data)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchCars()
  }, [])


  if (loading) {
    return <Page.Preloader />
  }

  return (
    <Fragment>

      <Page.BackLink
        title='Вернуться к тарифам'
        linkTo='/tariffs'
      />

      <div className='page-wrapper'>

        <Page.Filter
          button={{
            title: 'Добавить новую',
            handler: Events.addHandler
          }}
        >

          <Form.DetailField
            type='filter'
            label='Автомобилей'
            value={`${cars?.length}`}
          />

        </Page.Filter>

        <Components.CarsTable
          items={cars}
          isCreate={isCreateCar}
        />

      </div>
    </Fragment>
  )
}

export default CarsPage