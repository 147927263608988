import { IRules } from "../pages/Settings/types"



export enum EVars {
  USER_DATA = 'USER_DATA'
}

export interface IAuthContext {
  token: string | null
  userID: string | null
  isAuthenticated: boolean
  rules: any
  type: string
  notif: boolean
  ready: boolean
  login: (token: string, userID: string, rules: any, type: string, notif: boolean) => any
  logout: () => any
}

export interface IScopesContext {
  scopes: any
}


export interface IUserContext {
  userID: string | null
  login: string | null
  rules: IRules | null
  type: string | null
  notif: boolean
  setNotif: (notif: boolean) => any
}


export interface IWSContext {
  messages: any[],
  setMessages: (messages: any) => any
}


export interface IConfigContext {
  services: {
    image_server_api: string
  },
  default_language: {
    slug: "ru",
    label: "Русский"
  },
  mode: "debug"
}
