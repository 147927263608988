import { ICellProps } from './../../../../types/table'

export const titles: ICellProps[] = [
  { type: 'text', text: 'Дата и время запроса' },
  { type: 'text', text: 'ФИО' },
  { type: 'text', text: 'Мобильный' },
  { type: 'text', text: 'Баланс' },
  { type: 'text', text: 'Запрашиваемая сумма' },
  { type: 'text', text: 'Номер карты' },
  { type: 'text', text: 'Действия' },
]