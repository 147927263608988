import React from 'react'

import TableWrapper from '../../../components/Table/Table'

import Track from './Track'

import { ITableProps } from './types'

import { titles } from './data'


const Table: React.FC<ITableProps> = ({
    items,
    padding
}) => {

    return (

        <TableWrapper
            header={{
                type: 'orders',
                cells: titles
            }}
            padding={padding}
        >

            {items.map(item => (
                <Track
                    key={item._id}
                    type='orders'
                    data={item}
                />
            ))}

        </TableWrapper>
    )
}



export default Table
