import React from 'react'

import { ITextareaProps } from './types'


const Textarea: React.FC<ITextareaProps> = ({
  type,
  label,
  name,
  placeholder = '',
  value,
  inputHandler
}) => {

  return (
    <div className={`input-custom-wrapper`}>

      <h4 className='input-custom-label'>
        {label}
      </h4>

      <textarea
        className={`
          textarea-custom
          textarea-custom--${type}
        `}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={inputHandler}
      />

    </div>
  )
}

export default Textarea