import React, { useState } from 'react'
import { IImageCellProps } from './types'


import Edit from '../../../../assets/edit.png'
import Check from '../../../../assets/check.png'



const ImageCell: React.FC<IImageCellProps> = ({
  src,
  changeHandler
}) => {

  const [isActive, toggleActive] = useState<boolean>(false)

  const Events = {
    activeHandler: () => {
      toggleActive(!isActive)
    }
  }

  return (
    <div className='table-cell table-cell--between'>

      {isActive ? (
        <input
          className='input-file'
          name='image'
          type='file'
          disabled={!isActive}
          onChange={changeHandler}
        />
      ) : (
          <img
            className='table-img'
            alt='Car'
            src={src}
          />
        )}

      <img
        className='btn-table-edit'
        alt={!isActive ? 'Edit' : 'Submit'}
        src={!isActive ? Edit : Check}
        onClick={Events.activeHandler}
      />

    </div>
  )
}


export default ImageCell
