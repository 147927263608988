import React from 'react'

import { Link } from 'react-router-dom'

import { IBackLinkProps } from './types'


const BackLink: React.FC<IBackLinkProps> = ({
  type,
  title,
  linkTo = '/',
  buttonHandler
}) => {


  if (type === 'button') {
    return (
      <button
        className='back-link'
        onClick={buttonHandler}
      >
        <div className='back-link-arrow'>back arrow</div>

        <span>{title}</span>
      </button>
    )
  }

  return (
    <Link
      className='back-link'
      to={linkTo}
    >
      <div className='back-link-arrow'>back arrow</div>

      <span>{title}</span>
    </Link>
  )
}

export default BackLink