import React, { useState, useContext, useEffect, Fragment } from 'react'

import * as Page from '../../components/Page'
import * as Contexts from '../../contexts'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { TChangedTarrif } from './types'
import { useParams } from 'react-router-dom'
import { config } from '../../config/config'



const HistoryPage: React.FC = () => {

  const { id } = useParams<{
    id: string
  }>()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [tariffs, setTariff] = useState<TChangedTarrif[]>([])

  const Callbacks = {
    FetchTariffs: async () => {
      try {
        const response: any = await request(`${config.API}/tariffs/${id}/changes`, 'GET', null, {
          Authorization: token
        })
        console.log('response.data', response.data)
        setTariff(response.data)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchTariffs()
  }, [])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }

  return (
    <Fragment>
      <Page.BackLink
        title='Вернуться к тарифам'
        linkTo='/tariffs'
      />

      <div className='page-wrapper'>
        <div className='page-header'></div>

        <Components.HistoryTable
          items={tariffs}
        />

      </div>
    </Fragment>
  )
}

export default HistoryPage