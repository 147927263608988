import React from "react";

import TableWrapper from "../../../../components/Table/Table";

import Track from "./Track";
import NewTariff from "./NewTariff";

import { ITableProps } from "./types";

import { titles } from "./data";

const Table: React.FC<ITableProps> = ({ items, isCreate }) => {
  return (
    <TableWrapper
      header={{
        type: "tariffs",
        cells: titles,
      }}
    >
      {isCreate ? <NewTariff /> : null}

      {items.map((item) =>
        !item?.hidden ? (
          <Track key={item._id} type="tariffs" data={item} />
        ) : null
      )}
    </TableWrapper>
  );
};

export default Table;
