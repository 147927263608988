import React, { createContext, useContext, useEffect, useState } from 'react'

import { AuthContext } from '.'
import { config } from '../config/config'
import { useHttp } from '../hooks/http.hook'
import { IConfigContext } from './types'



const ConfigContext = createContext<IConfigContext | null>(null)

export default ConfigContext



export const ConfigProvider: React.FC = ({ children }) => {

  const { token } = useContext(AuthContext)
  const { request } = useHttp()

  const [data, setData] = useState<IConfigContext | null>(null)

  const _actions = {
    GET: async () => {
      const response: IConfigContext = await request(`${config.serverURL}api/config`, 'GET', null, {
        Authorization: token
      })
      setData(response)
    }
  }

  useEffect(() => {
    if (token) _actions.GET()
  }, [token])

  return (
    <ConfigContext.Provider value={{
      ...data!
    }}>
      {children}
    </ConfigContext.Provider>
  )
}