import React from 'react'

import { IDetailFieldProps } from './types'
import {Link} from "react-router-dom";


const MultiDataField: React.FC<IDetailFieldProps> = ({
   type,
   label,
   values,
   link,
   fontWeight = 'bold',
   buttonHandler
}) => {

    if (type === 'link') {
        return (
            <div className='detail-field detail-field--filter'>
                <h4 className='detail-field-label detail-field-label--filter'>
                    {label}
                </h4>

                {(values as any).map((value: any) => {
                    return <p key={value._id} className={`detail-field-value detail-field-value--${fontWeight}`}>
                        <Link to={`${link}${value._id}`}>{value.firstName ? value.firstName : '-'}</Link><button onClick={() => buttonHandler ? buttonHandler(value._id) : null}>&#10005;</button>
                        </p>
                    }
                )}
            </div>
        )
    }

    if (type === 'unlink') {
        return (
            <div className='detail-field detail-field--filter'>
                <h4 className='detail-field-label detail-field-label--filter'>
                    {label}
                </h4>

                {values.map((value: any) => {
                return <p key={value._id} className={`detail-field-value detail-field-value--${fontWeight}`}>
                    <span>{value.firstName ? value.firstName : '-'}</span>
                </p>
                    }
                )}
            </div>
        )
    }

    return (
        <div className='detail-field'>
            <h4 className='detail-field-label'>
                {label}
            </h4>

            {values.map((value: any) => {
            return <p key={value._id} className={`detail-field-value detail-field-value--${fontWeight}`}>
                {value.firstName ? value.firstName : '-'}
            </p>
            }
            )}
        </div>
    )
}

export default MultiDataField
