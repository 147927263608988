import { ICellProps } from './../../../../types/table'
import { IOption } from '../../../../components/Form/types'

export const titles: ICellProps[] = [
  { type: 'text', text: 'Тип авто' },
  { type: 'text', text: 'Изображение' },
  { type: 'text', text: 'Грузовой отсек' },
  { type: 'text', text: '' },
  { type: 'text', text: 'Мин. Заказ(часов)' },
  { type: 'text', text: 'Стоимость (час)' },
  { type: 'text', text: 'Авто' }
]

export const TypeOptions: IOption[] = [
  { title: 'S', value: 'S' },
  { title: 'M', value: 'M' },
  { title: 'L', value: 'L' },
]
