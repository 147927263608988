import React from 'react'

const Dropdown: React.FC<any> = ({
  items,
  item,
  changeHandler = () => { }
}) => {

        return(
            <div>
                <div className="input-field col s12">
                    <select defaultValue={(item) ? item : ''} onChange={changeHandler}>
                        <option value="" disabled>Выберите тариф</option>
                        {items.map((itemMap: any) => (item === itemMap._id) ? <option key={itemMap._id} value={itemMap._id} selected>{itemMap.title}</option> : <option key={itemMap._id} value={itemMap._id}>{itemMap.title}</option>)}
                    </select>
                    <label></label>
                </div>
            </div>
        )
};

export default Dropdown
