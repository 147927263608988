import React, { useContext } from "react";

import * as Contexts from "../../../contexts";
import { config } from "../../../config/config";

import { useHttp } from "../../../hooks/http.hook";
import { useHistory, useParams } from "react-router-dom";

import Cell from "../../../components/Table/Cell";

import { ITrackProps } from "./types";

const Track: React.FC<ITrackProps> = ({ type, data }) => {
  const history = useHistory();
  console.log("history.location.pathname11", history.location.pathname);
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const Events = {
    deleteHandler: () => {
      Callbacks.DeleteOrder();
    },
    cancelHandler: () => {
      Callbacks.CancelOrder();
    },
  };

  const Callbacks = {
    DeleteOrder: async () => {
      try {
        const response = window.confirm("Delete?");

        if (response) {
          // const response: any = request('', 'DELETE', null, {
          //     Authorization: token
          // })
          // await window.location.reload(false)
        }
      } catch (e) {
        console.log(e);
      }
    },
    CancelOrder: async () => {
      try {
        const response = window.confirm("Cancel?");

        if (response) {
          await request(
            `${config.ordersAPI}/${data._id}/cancel`,
            "POST",
            null,
            {
              Authorization: token,
            }
          );
          await window.location.reload(false);
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  return (
    <div className={`table-track table-track--${type}`}>
      <Cell
        paymentApproved={data.paymentApproved}
        textWeight="bold"
        type="column"
        linkTitle={"#" + data.index}
        linkTo={`/orders/${data._id}`}
        buttonTitle={
          data.status === "Поиск водителя" ? "Отменить заказ" : undefined
        }
        buttonType={data.status === "Поиск водителя" ? "delete" : undefined}
        buttonHandler={
          data.status === "Поиск водителя" ? Events.cancelHandler : undefined
        }
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={new Date(data.orderTime).toLocaleDateString()}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.distance}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.status}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        type="link"
        textWeight="bold"
        text={data?.client?.phone}
        linkTitle={data?.client?.firstName}
        linkTo={`${
          history.location.pathname.slice(0, 10) === "/consumers"
            ? "/consumers"
            : "/consumers"
        }/${data?.client?._id}`}
        style={{
          flexDirection: "column-reverse",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      />

      {data?.driver?.firstName ? (
        <Cell
          paymentApproved={data.paymentApproved}
          type="link"
          textWeight="bold"
          text={data?.driver?.phone}
          linkTitle={data?.driver?.firstName}
          linkTo={`${
            history.location.pathname.slice(0, 10) === "/drivers"
              ? "/drivers"
              : "/drivers"
          }/${data?.driver?._id}`}
          style={{
            flexDirection: "column-reverse",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        />
      ) : (
        <Cell
          paymentApproved={data.paymentApproved}
          text={"-"}
          style={{
            alignItems: "flex-start",
          }}
        />
      )}

      <Cell
        paymentApproved={data.paymentApproved}
        text={
          data?.car?.mark?.title && data?.car?.car_number
            ? data?.car?.mark?.title + ",\n\t" + data?.car?.car_number
            : ""
        }
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.user?.title ? data?.user?.title : "f1 cargo"}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.comment ? data?.comment : "-"}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.sum + "грн"}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={data?.typeOfPayment}
        style={{
          alignItems: "flex-start",
        }}
      />

      <Cell
        paymentApproved={data.paymentApproved}
        text={
          data?.tariff?.title + ",\n" + data?.tariff?.pricePerHour + "грн/час"
        }
        style={{
          alignItems: "flex-start",
        }}
      />
    </div>
  );
};

export default Track;
