import React from 'react'

import TableWrapper from '../../../../components/Table/Table'

import Track from './Track'
import NewUser from './NewUser'

import { ITableProps } from './types'

import { titles } from './data'


const Table: React.FC<ITableProps> = ({
    items,
    isCreate
}) => {

    return (
        <TableWrapper
            header={{
                type: 'users',
                cells: titles
            }}
        >

            {isCreate ? (
                <NewUser />
            ) : null}

            {items.map(item => (
                <Track
                    key={item._id}
                    type='users'
                    data={item}
                />
            ))}

        </TableWrapper>
    )
}



export default Table