import React from 'react'

import Card from './Card'
import NewCard from './NewCard'

import { ITableProps } from './types'


const Table: React.FC<ITableProps> = ({
    items,
    isCreate
}) => {

    return (
        <div className='settings-table'>

            {isCreate ? (
                <NewCard />
            ) : null}

            {items.map(item => (
                <Card
                    key={item._id}
                    data={item}
                />
            ))}
        </div>
    )
}

export default Table
