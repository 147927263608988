import React, { Fragment, useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { config } from '../../config/config'

import { IColor } from './types'


const CarsPage: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [colors, setColors] = useState<IColor[]>([])
  const [isCreate, toggleCreate] = useState<boolean>(false)

  const Events = {
    addHandler: () => {
      toggleCreate(!isCreate)
    },
  }

  const Callbacks = {
    FetchCars: async () => {
      try {
        const response: any = await request(
          `${config.API}/colors`,
          'GET', null, {
          Authorization: token
        })

        if (response.data) setColors(response.data)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchCars()
  }, [])


  if (loading) {
    return <Page.Preloader />
  }

  return (
    <Fragment>

      <Page.BackLink
        title='Вернуться к тарифам'
        linkTo='/tariffs'
      />

      <div className='page-wrapper'>

        <Page.Filter
          button={{
            title: 'Добавить новый',
            handler: Events.addHandler
          }}
        >

          <Form.DetailField
            type='filter'
            label='Цетов'
            value={`${colors?.length}`}
          />

        </Page.Filter>

        <Components.ColorsTable
          items={colors}
          isCreate={isCreate}
        />

      </div>
    </Fragment>
  )
}

export default CarsPage