import React, { Fragment } from "react";

import * as Buttons from "../Buttons";
import * as Form from "../Form";

import { IFilterProps } from "./types";

const Filter: React.FC<IFilterProps> = ({
  children,
  form,
  button,
  agePicker,
  actionSelect = false,
  dateSelect = false,
  registerDateSelect = false,
  dateTimeSelect = false,
  fullNameSelect = false,
  carsSelect = false,
  balanceSelect = false,
  statusSelect = false,
  ordersStatusSelect = false,
  tariffSelect = false,
  sumSelect = false,
  fullNameInput,
  carInput,
  orderInput,
  phoneInput,
  allMatchesInput,
  events = {},
}) => {
  return (
    <div className="filter-bar">
      <div className="filter-bar-data">{children}</div>

      <div className="filter-bar-inputs">
        {events && agePicker ? (
          <Fragment>
            <Form.Input
              type="date"
              name="dateFrom"
              value={form?.dateFrom}
              label="Показать за период"
              maxDate={form.dateTo}
              disabled={!form.dateTo}
              inputHandler={events.inputHandler as any}
            />

            <Form.Input
              type="date"
              name="dateTo"
              minDate={form?.dateFrom}
              value={form?.dateTo}
              inputHandler={events.inputHandler as any}
            />

            <button
              className={"page-date-filter-btn"}
              onClick={events.timeHandler as any}
            >
              Показать за период
            </button>
          </Fragment>
        ) : null}

        {events.selectHandler ? (
          <Form.Select
            name="action"
            label="Сортировка"
            actionSelect={actionSelect}
            dateSelect={dateSelect}
            registerDateSelect={registerDateSelect}
            fullNameSelect={fullNameSelect}
            dateTimeSelect={dateTimeSelect}
            sumSelect={sumSelect}
            ordersStatusSelect={ordersStatusSelect}
            carsSelect={carsSelect}
            balanceSelect={balanceSelect}
            statusSelect={statusSelect}
            tariffSelect={tariffSelect}
            value={`${form?.sortBy}-${form?.sortOrder}`}
            selectHandler={events.selectHandler as any}
          />
        ) : null}

        {events && phoneInput ? (
          <Form.Input
            name="phone"
            value={form?.phone}
            label="Номер телефона"
            inputHandler={events.inputHandler as any}
            pressHandler={events.pressHandler}
          />
        ) : null}

        {events && orderInput ? (
          <Form.Input
            name="orderNumber"
            value={form?.orderNumber}
            label="Номер заказа"
            placeholder="#"
            inputHandler={events.inputHandler as any}
            pressHandler={events.pressHandler}
          />
        ) : null}

        {events && fullNameInput ? (
          <Form.Input
            name="fullName"
            label="Поиск по имени"
            value={form?.fullName}
            inputHandler={events.inputHandler as any}
            pressHandler={events.pressHandler}
          />
        ) : null}

        {events && carInput ? (
          <Form.Input
            name="title"
            label="Поиск по названию"
            value={form?.title}
            inputHandler={events.inputHandler as any}
            pressHandler={events.pressHandler}
          />
        ) : null}

        {events && allMatchesInput ? (
          <Form.Input
            name="query"
            label="Поиск"
            value={form?.query}
            inputHandler={events.inputHandler as any}
            pressHandler={events.pressHandler}
          />
        ) : null}

        {phoneInput ||
        orderInput ||
        fullNameInput ||
        carInput ||
        allMatchesInput ? (
          <button className="btn-search" onClick={events?.searchHandler}>
            <img
              alt="Search"
              className="btn-search-img"
              src={require("../../assets/search.png")}
            />
          </button>
        ) : null}

        {button ? (
          <Buttons.Default
            type="button"
            buttonType="plus"
            title={button.title}
            buttonHandler={button.handler}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
