import React, { useContext, useState } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'
import * as Form from '../../../../components/Form'

import { config } from '../../../../config/config'
import { useHttp } from '../../../../hooks/http.hook'
import { IColor } from '../../types'



const NewColor: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [form, setForm] = useState<IColor>({
    ua: '',
    ru: '',
    en: ''
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: () => {
      Callbacks.Create()
    }
  }

  const Callbacks = {
    Create: async () => {
      try {
        await request(`${config.API}/colors`, 'POST', { ...form }, {
          Authorization: token
        })
        await window.location.reload(false)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className='table-track table-track--colors'>

      <Table.CustomCell>
        <Form.Input
          name='ru'
          value={form.ru}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          name='en'
          value={form.en}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          name='ua'
          value={form.ua}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.Cell
        buttonTitle='Создать'
        buttonType='default'
        buttonHandler={Events.createHandler}
      />

    </div>
  )
}
export default NewColor