import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../../contexts'

import { useHttp } from '../../../hooks/http.hook'

import { ICardProps } from './types'
import { IRules } from '../types'

import { config } from '../../../config/config'

import PersonEdit from './PersonEdit'
import Checkboxes from './Checkboxes'


const Card: React.FC<ICardProps> = ({
    data
}) => {

    const { token } = useContext(Contexts.AuthContext)

    const { loading, request } = useHttp()

    const [isLogin, toggleLogin] = useState<boolean>(true)
    const [form, setForm] = useState({
        login: '',
        rules: {
            orders: false,
            drivers: false,
            companies: false,
            consumers: false,
            finance: false,
            settings: false,
            statistics: false,
            tariffs: false,
            users: false,
            withdraw: false
        }
    })

    const Events = {
        inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.type === 'text') {
                console.log(data._id)
                setForm({ ...form, [e.target.name]: e.target.value })
            } else if (e.target.type === 'checkbox') {
                console.log(data._id)
                setForm({
                    ...form, rules: {
                        ...form.rules,
                        [e.target.name]: e.target.checked
                    }
                })
            }
        },
        saveHandler: () => {
            Callbacks.Save()
        },
        deleteHandler: () => {
            Callbacks.Delete()
        }
    }

    const Callbacks = {
        Save: async () => {
            try {
                 const response = await request(`${config.API}/settings/${data._id}`, 'PATCH', { ...form }, {
                   Authorization: token
                 })
            } catch (e) {
                console.log(e)
            }
        },
        Delete: async () => {
            try { console.log(data._id)
                 const response = await request(`${config.API}/settings/${data._id}`, 'DELETE', null, {
                   Authorization: token
                 })

                if (response) {
                  await window.location.reload(false)
                }
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        setForm({ ...form, login: data.login, rules: (data.rules as IRules) })
    }, [data])

    useEffect(() => {
        if (form.login.length >= 4) toggleLogin(true)
        else toggleLogin(false)
    }, [form.login])


    return (
        <div className='settings-card'>
            <div className='settings-card-container'>

                <button
                    className='settings-card-delete'
                    onClick={Events.deleteHandler}
                >
                    <img
                        src={require('../../../assets/delete.png')}
                        alt='delete'
                    />
                </button>

                <PersonEdit
                    _id={data._id}
                    login={form.login}
                    isLogin={isLogin}
                    disabledButton={loading}
                    inputHandler={Events.inputHandler}
                    saveHandler={Events.saveHandler}
                >

                    <Checkboxes
                        id={data._id}
                        items={form.rules}
                        inputHandler={Events.inputHandler}
                    />

                </PersonEdit>

            </div>
        </div>
    )
}

export default Card
