import React from 'react'

import { ITableProps } from './types'


const Table: React.FC<ITableProps> = ({
  type,
  title,
  margin,
  buttonHandler
}) => {

  return (
    <button
      className={`table-btn table-btn--${type} ${margin ? 'margin' : ''}`}
      onClick={buttonHandler}
    >

      {type === 'delete' ? (
        <img
          alt='Remove'
          src={require('../../assets/cross.png')}
        />
      ) : type === 'save' ? (
        <img
          alt='Remove'
          src={require('../../assets/plus.png')}
        />
      ) : null}

      <span>
        {title}
      </span>
    </button>
  )
}
export default Table
