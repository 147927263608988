import { ICellProps } from './../../../../types/table'

export const titles: ICellProps[] = [
  { type: 'text', text: 'ФИО', headerData: { name: 'fullName' } },
  { type: 'text', text: 'Дата регистрации', headerData: { name: 'createdAt' } },
  { type: 'text', text: 'Мобильный' },
  { type: 'text', text: 'Заказов выполено' },
  { type: 'text', text: 'Статус' },
  { type: 'text', text: 'Сумма' },
  { type: 'text', text: 'Комиссия' },
  { type: 'text', text: 'Авто' },
  { type: 'text', text: 'Компания' },
  { type: 'text', text: 'Документы' },
  { type: 'text', text: 'Баланс' },
  { type: 'text', text: 'Подробнее' },
]
