export const Translate = {
  orders: 'Заказы',
  drivers: 'Водители',
  companies: 'Грузокомпании',
  consumers: 'Клиенты',
  finance: 'Финансы',
  settings: 'Настройки',
  statistics: 'Статистика',
  tariffs: 'Тарифы',
  users: 'Компании',
  withdraw: 'Вывод средств'
}
