import { useCallback } from "react"


export const useMesasge = () => {
    return useCallback((text: string) => {
        //@ts-ignore
        if (window.M && text) {
            //@ts-ignore
            window.M.toast({ html: text })
        }
    }, [])
}