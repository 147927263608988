import React, {useContext, useState, useEffect, Fragment, ChangeEvent} from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import { config } from '../../config/config'

import { useMesasge } from "../../hooks/message.hook";
import JSZip from "jszip";
// import JSZipUtils from "jszip-utils";
import { saveAs } from 'file-saver';

import { TModalContent, IDriverCar } from './types'

// import { CarDummy } from './data'


const DocsPage: React.FC = () => {

  let Callbacks: { FetchCar: () => Promise<void>; Confirm: () => Promise<void>; FetchTariffs: () => Promise<void>; Download: () => Promise<void>; SaveData: () => Promise<void> };
  const {id} = useParams()
  const history = useHistory()
  const message = useMesasge()

  const {token} = useContext(Contexts.AuthContext)
  const {loading, request} = useHttp()

  const [car, setCar] = useState<IDriverCar | null>(null)
  const [modal, setModal] = useState<TModalContent>(null)
  const [tariffs, setTariffs] = useState<any[]>([])
  const [tariff, setTariff] = useState<string>('')
  const [form, setForm] = useState<string>('')
  const [confirm, setConfirm] = useState<boolean>(true)

  const [images, setImages] = useState<string[]>([])


  const Events = {
    goBack: () => {
      history.goBack()
    },
    confirmHandler: () => {
      Callbacks.Confirm()
    },
    changeHandler: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      setForm(e.target.value)
    },
    deactivateHandler: () => {
      setModal('deactivate')
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    rejectHandler: () => {
      setModal('reject')
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    },
    saveHandler: () => {
      Callbacks.SaveData()
    },
    downloadHandler: () => {
      Callbacks.Download()
    }
  }

  Callbacks = {
    FetchTariffs: async () => {
      try {
        const response: any = await request(
            `${config.API}/cars/${id}/availableTariffs`, 'GET', null, {
              Authorization: token
            })

        setTariffs(response.data)
      } catch (e) {
        console.log(e)
      }
    },
    FetchCar: async () => {
      try {
        const response: any = await request(
            `${config.API}/cars/${id}`, 'GET', null, {
              Authorization: token
            })

        setCar(response.data)
        setTariff(response.data?.tariff);
      } catch (e) {
        console.log(e)
      }
    },
    SaveData: async () => {
      try {
        const response = await request(`${config.API}/cars/${id}/setTariff`, 'POST', {tariff: (form) ? form : tariff}, {
          Authorization: token
        }, true)

        if (response.status === 402) {
          message('У пользователя есть активный заказ!')
        } else if (response.status === 500) {
          message('Документы просрочены!')
        } else {
          await Callbacks.FetchCar()
          // await window.location.reload(true)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Confirm: async () => {
      try {
        const response = await request(`${config.API}/cars/${id}/status/active`, 'POST', {tariff: (form) ? form : tariff}, {
          Authorization: token
        }, true)
        setConfirm(false)

        if (response.status === 402) {
          setConfirm(true)
          message('У пользователя есть активный заказ!')
        } else if (response.status === 500) {
          setConfirm(true)
          message('Документы просрочены!')
        }

        if (response.status !== 500 && response.status !== 402) {
          await request(`${config.API}/statistics`, 'POST', null, {
            Authorization: token
          })
          setConfirm(true)
          await window.location.reload(true)
        }
      } catch (e) {
        console.log(e)
      }
    },
    Download: async () => {
      try {
        const jsZipUtils = require("jszip-utils");
        let zip = new JSZip();
        let readyImg;
        const arrTechImages = [car?.documents.technicalPassport.photos.one, car?.documents.technicalPassport.photos.two, car?.documents.technicalPassport.photos.other];
        const arrRightsImages = [car?.documents.rights.photos.back, car?.documents.rights.photos.front];
        const arrCargoImages = [car?.cargoCompartment.photos.one, car?.cargoCompartment.photos.two, car?.cargoCompartment.photos.other];
        images.forEach((image, key) => {
          readyImg = jsZipUtils.getBinaryContent(image);
          (zip.folder('cars') as JSZip).file('img' + key + '.jpg', readyImg, {binary: true, compression: "DEFLATE"});
        });

        arrCargoImages.forEach((cargoImg, keyCargo) => {
          if (!Array.isArray(cargoImg)) {
            readyImg = jsZipUtils.getBinaryContent(cargoImg);
            (zip.folder('cargo') as JSZip).file('img' + keyCargo + '.jpg', readyImg, {
              binary: true,
              compression: "DEFLATE"
            });
          } else {
            cargoImg.forEach((img, key2) => {
              readyImg = jsZipUtils.getBinaryContent(img);
              (zip.folder('technicalPassport') as JSZip).file('img' + key2 + '.jpg', img, {
                binary: true,
                compression: "DEFLATE"
              });
            });
          }
        });

        arrTechImages.forEach((techImg, key1) => {
          if (!Array.isArray(techImg)) {
            readyImg = jsZipUtils.getBinaryContent(techImg);
            (zip.folder('technicalPassport') as JSZip).file('img' + key1 + '.jpg', readyImg, {
              binary: true,
              compression: "DEFLATE"
            });
          } else {
            techImg.forEach((img, key2) => {
              readyImg = jsZipUtils.getBinaryContent(img);
              (zip.folder('technicalPassport') as JSZip).file('img' + key1 + '.jpg', img, {
                binary: true,
                compression: "DEFLATE"
              });
            });
          }
        });

        readyImg = jsZipUtils.getBinaryContent(car?.documents.insurance.photo);
        (zip.folder('insurance') as JSZip).file('img' + 'one' + '.jpg', readyImg, {binary: true, compression: "DEFLATE"});
        arrRightsImages.forEach((rightsImg, keyRights) => {
          readyImg = jsZipUtils.getBinaryContent(rightsImg);
          (zip.folder('rights') as JSZip).file('img' + keyRights + '.jpg', readyImg, {binary: true, compression: "DEFLATE"});
        });

         saveAs(await zip.generateAsync({type: 'blob'}), id + '.zip');
      } catch (e) {
        console.log(e)
      }
    }
  };

  useEffect(() => {
    Callbacks.FetchTariffs()
    Callbacks.FetchCar()
  }, [])

  useEffect(() => {
    const arr: string[] = []
    arr.push(car?.photos.front!)
    arr.push(car?.photos.back!)
    arr.push(car?.photos.left!)
    arr.push(car?.photos.right!)
    for (let i = 0; i < car?.photos.other.length!; i++) {
      arr.push(car?.photos.other[i]!)
    }
    setImages(arr)
  }, [car?.photos])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }

  return (
    <Fragment>

      <Components.Modal
        type={modal}
        data={car}
        images={images}
        clearModal={Events.clearModal}
      />

      <Page.BackLink
        type='button'
        title='Вернуться к профилю водителя'
        buttonHandler={Events.goBack}
      />

      <div className='page-wrapper'>
        <div className='docs-wrapper'>

          <div className='docs-column docs-column--large'>
            <Components.MainBlock
              tariffs={tariffs}
              tariff={tariff}
              data={car}
              images={images}
              confirmBtn={confirm}
              changeHandler={Events.changeHandler}
              confirmHandler={Events.confirmHandler}
              deactivateHandler={Events.deactivateHandler}
              rejectHandler={Events.rejectHandler}
              saveHandler={Events.saveHandler}
              downloadHandler={Events.downloadHandler}
            />

            <Components.CargoBlock
              data={car}
            />

          </div>

          <div className='docs-column docs-column--small'>
            <Components.DocBlock
              title='Права'
              data={car}
            />

            <Components.DocBlock
              title='Страховка'
              data={car}
            />

            <Components.DocBlock
              title='Техпаспорт'
              data={car}
            />
          </div>

        </div>
      </div>

    </Fragment>
  )
}


export default DocsPage
