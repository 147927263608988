import React from 'react'

import { IModal } from './types'



const Modal: React.FC<IModal> = ({
  isActive,
  children,
  clearModal
}) => {

  const Events = {
    closeHandler: () => {
      clearModal()
    }
  }

  return (
    <div
      className={`page-modal ${isActive ? 'active' : ''}`}
      onClick={Events.closeHandler}
    >
      <div className='page-modal-wrapper'>
        {children}
      </div>
    </div>
  )
}

export default Modal