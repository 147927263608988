import React, { Fragment } from 'react'

import * as Form from '../../../../components/Form'

import { ITrackProps } from './types'

import Cell from '../../../../components/Table/Cell'
import CustomCell from '../../../../components/Table/CustomCell'



const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {


  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        direction='column'
        text={new Date(data.createdAt).toLocaleDateString()}
      />

      <CustomCell>
        {data.title && data.title.old !== data.title.new ? (
          <Form.DetailField
            fontWeight='normal'
            value={`${data.title.old} -> ${data.title.new}`}
          />
        ) : <></>}
      </CustomCell>

      <CustomCell>
        <Fragment>
          {data.lengthRange && data.lengthRange.min.old !== data.lengthRange.min.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Длина от (м):'
              value={`${data.lengthRange.min.old} -> ${data.lengthRange.min.new}`}
            />
          ) : <></>}

          {data.widthRange && data.widthRange.min.old !== data.widthRange.min.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Ширина от (м):'
              value={`${data.widthRange.min.old} -> ${data.widthRange.min.new}`}
            />
          ) : <></>}

          {data.heightRange && data.heightRange.min.old !== data.heightRange.min.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Высота от (м):'
              value={`${data.heightRange.min.old} -> ${data.heightRange.min.new}`}
            />
          ) : null}

          {data.weightRange && data.weightRange.min.old !== data.weightRange.min.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Грузоподъемность от (т):'
              value={`${data.weightRange.min.old} -> ${data.weightRange.min.new}`}
            />
          ) : <></>}

          {data.capacityRange && data.capacityRange.min.old !== data.capacityRange.min.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Объем от (т):'
              value={`${data.capacityRange.min.old} -> ${data.capacityRange.min.new}`}
            />
          ) : <></>}
        </Fragment>
      </CustomCell>

      <CustomCell>
        <Fragment>
          {data.lengthRange && data.lengthRange.max.old !== data.lengthRange.max.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Длина до (м):'
              value={`${data.lengthRange.max.old} -> ${data.lengthRange.max.new}`}
            />
          ) : <></>}

          {data.widthRange && data.widthRange.max.old !== data.widthRange.max.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Ширина до (м):'
              value={`${data.widthRange.max.old} -> ${data.widthRange.max.new}`}
            />
          ) : <></>}

          {data.heightRange && data.heightRange.max.old !== data.heightRange.max.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Высота до (м):'
              value={`${data.heightRange.max.old} -> ${data.heightRange.max.new}`}
            />
          ) : null}

          {data.weightRange && data.weightRange.max.old !== data.weightRange.max.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Грузоподъемность до (т):'
              value={`${data.weightRange.max.old} -> ${data.weightRange.max.new}`}
            />
          ) : <></>}

          {data.capacityRange && data.capacityRange.max.old !== data.capacityRange.max.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Объем от (т):'
              value={`${data.capacityRange.max.old} -> ${data.capacityRange.max.new}`}
            />
          ) : <></>}
        </Fragment>
      </CustomCell>

      <CustomCell>
        <Fragment>
          {data.minOrdersHours && data.minOrdersHours.old !== data.minOrdersHours.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Стоимость (час)'
              value={`${data.minOrdersHours.old} -> ${data.minOrdersHours.new}`}
            />
          ) : <></>}
        </Fragment>
      </CustomCell>

      <CustomCell>
        <Fragment>
          {data.pricePerHour && data.pricePerHour.old !== data.pricePerHour.new ? (
            <Form.DetailField
              fontWeight='normal'
              label='Стоимость (час):'
              value={`${data.pricePerHour.old} -> ${data.pricePerHour.new}`}
            />
          ) : <></>}
        </Fragment>
      </CustomCell>

    </div>
  )
}


export default Track