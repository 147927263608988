import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks/http.hook";

import { config } from "../../config/config";

import { IPagination, IFilter } from "../../types";
import { IDriver } from "./types";

import * as Form from "../../components/Form"; // waiting for API

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [drivers, setDrivers] = useState<IDriver[]>([]);
  const [xslsData, setXSLSData] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "fullName",
    sortOrder: "asc",
    query: "",
  });
  const history = useHistory();

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1];
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    sortHandler: (e: React.MouseEvent<HTMLButtonElement>) => {
      const { sort } = (e.target as HTMLButtonElement).dataset;
      const sortBy = (sort as string).split("-")[0];
      const sortOrder = (sort as string).split("-")[1];
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.FetchDrivers(1);
    },
    searchHandler: () => {
      Callbacks.FetchDrivers(1);
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
  };

  const Callbacks = {
    FetchDrivers: async (page?: number) => {
      try {
        const response: any = await request(
          `${config.API}/drivers?page=${
            page ? page : pagination.page
          }&perPage=${pagination.perPage}&sortOrder=${
            filter.sortOrder
          }&sortBy=${filter.sortBy}&allMatches=${filter.query}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        const responseSXLS: any = await request(
          `${config.API}/drivers?page=${1}&perPage=${
            response?.pagination?.total
          }&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];

        if (!response.data) {
          history.push("/perm_error");
        } else {
          response.data.forEach((item: any) => {
            if (item.status === "active") {
              item.status = "Активный";
            } else if (item.status === "blocked") {
              item.status = "Заблокирован";
            }
          });

          responseSXLS.data.forEach((resp: any) => {
            let cars: any = "";
            if (resp.status === "active") {
              resp.status = "Активный";
            } else if (resp.status === "blocked") {
              resp.status = "Заблокирован";
            }

            resp.cars?.forEach((car: any) => {
              cars += "" + car.mark?.title;
            });

            Arr.push({
              fullName: resp.fullName,
              registerDate: new Date(resp.createdAt).toLocaleDateString(),
              status: resp.status,
              phone: resp.phone,
              cars: cars,
              ordersCount: resp.ordersCount ? resp.ordersCount : 0,
              buyByCash: resp.cash ? resp.cash : 0,
              buyOnline: resp.onlineSum ? resp.onlineSum : 0,
              totalSum: resp.total ? resp.total : 0,
              commission: resp.commission,
              balance: resp.balance,
            });
          });
        }

        setXSLSData(Arr);
        setDrivers(response.data);
        if (response.pagination)
          setPagination({
            ...pagination,
            totalCount: response.pagination.total,
            page: response.pagination.page,
          });
        else setPagination({ ...pagination, totalCount: 0 });
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchDrivers();
  }, [filter.sortBy, filter.sortOrder, pagination.page]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <div className="page-wrapper">
      <Page.Filter
        fullNameSelect
        registerDateSelect
        balanceSelect
        allMatchesInput
        form={filter}
        events={Events}
      >
        <Form.DetailField
          type="filter"
          label="Водителей"
          value={`${pagination.totalCount}`}
        />
        <CSV.ExcelUpload csvData={xslsData} fileName={"drivers"} />
      </Page.Filter>
      <Components.Table items={drivers} sortHandler={Events.sortHandler} />

      <Page.Pagination
        linkTo="/drivers"
        pagination={pagination}
        paginateHandler={Events.paginateHandler}
      />
    </div>
  );
};

export default IndexPage;
