import React, { useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../Page'

import Router from '../../router/router'


const Computed: React.FC = () => {

    const { isAuthenticated } = useContext(Contexts.AuthContext)

    return (
        <div className="app-wrapper">

            {isAuthenticated && <Page.Header />}
            {isAuthenticated && <Page.SideBar />}
            {isAuthenticated && <Page.NotifBar />}

            <div className={`app-container ${isAuthenticated && 'authenticated'}`}>
                <Router />
            </div>
        </div>
    )
}


export default Computed
