import React from 'react'

import * as Form from '../../components/Form'

import { IInputCellProps } from './types'



const PasswordCell: React.FC<IInputCellProps> = ({
 // name,
 value,
 isCorrect,
 changeHandler
}) => {

    return (
        <div className='table-cell table-cell--top'>
            <Form.PasswordInputToggle
                type='text'
                name="password"
                isCorrect={isCorrect}
                value={value}
                changeHandler={changeHandler}
            />
        </div>
    )
}
export default PasswordCell
