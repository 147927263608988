import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { config } from '../../config/config'

import { useHttp } from '../../hooks/http.hook'

import { IUser } from './types'

import * as CSV from '../../components/ExcelUpload'

const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)

    const { loading, request } = useHttp()

    const [users, setUsers] = useState<IUser[]>([])
    const [isCreate, toggleCreate] = useState<boolean>(false)
    const [xslsData, setXSLSData] = useState<any>()

    const Events = {
        addHandler: () => {
            toggleCreate(!isCreate)
        }
    }

    const Callbacks = {
        FetchUsers: async () => {
            try {
                 const response: any = await request(`${config.API}/users`, 'GET', null, {
                     Authorization: token
                 })

                const responseSXLS: any = await request(
                    `${config.API}/users`,
                    'GET', null, {
                        Authorization: token
                    })

                const Arr: any = [];

                responseSXLS.data.forEach((resp: any, key1: any) => {
                    Arr.push({
                        name: resp.title,
                        email: resp?.loginData?.email,
                        description: resp.description,
                        driversCount: resp.drivers.length,
                        balance: resp.balance,
                    });
                });

                 setXSLSData(Arr)

                 setUsers(response.data)

               // setUsers(usersDummy)
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        Callbacks.FetchUsers()
    }, [])


    if (loading) {
        return (
            <Page.Preloader />
        )
    }

    return (
        <div className='page-wrapper'>

            <Components.Header
                addHandler={Events.addHandler}
            />
            <div style={{ marginLeft: '40px', marginTop: '-100px', marginBottom: '40px' }}><CSV.ExcelUpload csvData={xslsData} fileName={'companies'}/></div>

            <Components.Table
                items={users}
                isCreate={isCreate}
            />

        </div>
    )
}

export default IndexPage
