import React, { useContext, useState, useEffect, Fragment } from 'react'
import {useHistory, useParams} from 'react-router-dom'

import * as Contexts from '../../contexts'
import * as Table from '../../components/Table'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { config } from '../../config/config'

import {IFilter, IPagination} from '../../types'
import { IOrder } from '../Orders/types'
import { IUser } from './types'

import { ordersDummy } from '../Orders/data' // waiting for API
import { usersDummy } from './data'



const DetailPage: React.FC = () => {

  const { id } = useParams()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [drivers, setDrivers] = useState<any>()
  const [user, setUser] = useState<IUser | null>(null)
  const [orders, setOrders] = useState<IOrder[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 5,
    totalCount: 0
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'date',
    sortOrder: 'asc',
    orderNumber: '',
  })
  const history = useHistory();

  let address: any = []
  let prefix: string = ''

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split('-')[0]
      const sortOrder = e.target.value.split('-')[1]
      setFilter({ ...filter, sortBy, sortOrder })
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.FetchOrders()
    },
    searchHandler: () => {
      Callbacks.FetchOrders()
    },
    blockHandler: () => {
      const response = window.confirm('Block?')
      if (response) Callbacks.Block()
    },
    unblockHandler: () => {
      const response = window.confirm('Unblock?')
      if (response) Callbacks.UnBlock()
    },
    deleteHandler: () => {
      const response = window.confirm('Delete?')
      if (response) Callbacks.Delete()
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    }
  }

  const Callbacks = {
    FetchUser: async () => {
      try {
         const response: any = await request(
           `${config.API}/users/${id}`, 'GET', null, {
           Authorization: token
         })

         setUser(response.data)

       // setUser(usersDummy[0])
      } catch (e) {
        console.log(e)
      }
    },
    FetchOrders: async (page?: number) => {
      try {
       const response: any = await request(
        `${config.API}/users/${id}/byUser/?page=${page ? page : pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}&_index=${filter.orderNumber}`,
        'GET', null, {
           Authorization: token
        })

        if (response.data) {
          response.data.forEach((item: any, key: number) => {
            item.time = new Date(item.time).toLocaleTimeString() + ' ' + new Date(item.time).toLocaleDateString();
            address[key] = '';
            if (item.status === 'searchDriver') {
              item.status = 'Поиск водителя';
            } else if (item.status === 'accepted') {
              item.status = 'Подтвержден';
            } else if (item.status === 'driverInGoing') {
              item.status = 'Водитель в пути';
            } else if (item.status === 'inProgress') {
              item.status = 'В процессе';
            } else if (item.status === 'waiting_for_payment') {
              item.status = 'Ожидает оплаты';
            } else if (item.status === 'done') {
              item.status = 'Выполнен';
            } else if (item.status === 'canceledByClient') {
              item.status = 'Отменен клиентом';
            } else if (item.status === 'canceledByAdmin') {
              item.status = 'Отменен администратором';
            } else if (item.status === 'paymentError') {
              item.status = 'Ошибка оплаты';
            } else if (item.status === 'paymentProcessing') {
              item.status = 'В процессе оплаты';
            } else if (item.status === 'expiredByTime') {
              item.status = 'Истек';
            }

            if (item.typeOfPayment === 'online') {
              item.typeOfPayment = 'Карта';
            } else {
              item.typeOfPayment = 'Наличные';
            }

            item.coordinates.forEach((item1: any, key1: number) => {
              prefix = (key1 > 0) ? '->' : '';
              address[key] += prefix + item1.displayTitle;
            })
            item.distance = address[key]
          })
          setOrders(response.data)
          // setOrders(ordersDummy)
        } else {
          history.push('/perm_error');
        }

        if (response.pagination) setPagination({ ...pagination, totalCount: response.pagination.total, page: response.pagination.page })
        else setPagination({ ...pagination, totalCount: 0 })
         // setOrders(ordersDummy)
      } catch (e) {
        console.log(e)
      }
    },
    UnBlock: async () => {
      try {
        await request(
            `${config.API}/users/unblock/${id}`, 'POST', null, {
              Authorization: token
            })
        await Callbacks.FetchUser()
      } catch (e) {
        console.log(e)
      }
    },
    Block: async () => {
      try {
        await request(
            `${config.API}/users/ban/${id}`, 'POST', null, {
              Authorization: token
            })
        await Callbacks.FetchUser()
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/users/${id}`, 'DELETE', null, {
          Authorization: token
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    Callbacks.FetchUser()
    Callbacks.FetchOrders()
  }, [filter.sortBy, filter.sortOrder, pagination.page])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }


  return (
    <Fragment>

      <Page.BackLink
        title='Вернуться к списку пользователей'
        linkTo='/users'
      />

      <div className='page-wrapper'>
        <Components.DetailBlock
          data={user}
          blockHandler={Events.blockHandler}
          unblockHandler={Events.unblockHandler}
          deleteHandler={Events.deleteHandler}
        />

        <Page.Filter
          dateSelect
          statusSelect
          orderInput
        //  fullNameInput
          form={filter}
          events={Events}
        >

          <Form.DetailField
              type='filter'
              label='Заказов'
              value={`${pagination?.totalCount}`}
          />

        </Page.Filter>

        { (orders[0]) ?
          <div>  <Table.OrdersTable
                items={orders}
                padding='bottom'
            /> <Page.Pagination
            linkTo={`/users/${id}`}
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          /> </div>: <div className="havenot-order">У этой компании нет заказов!</div>}

      </div>
    </Fragment>
  )
}

export default DetailPage
