import React from 'react'

import { ICustomCellProps } from '../../pages/Tariffs/components/History/types'


const CustomCell: React.FC<ICustomCellProps> = ({
  children
}) => {

  return (
    <div className='table-cell table-cell--column'>
      {children}
    </div>
  )
}

export default CustomCell