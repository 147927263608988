import { ICellProps } from '../../../types/table'

export const titles: ICellProps[] = [
  { type: 'text', text: 'Номер заказа' },
  { type: 'text', text: 'Дата/Время' },
  { type: 'text', text: 'Направление' },
  { type: 'text', text: 'Статус' },
  { type: 'text', text: 'Клиент' },
  { type: 'text', text: 'Водитель' },
  { type: 'text', text: 'Тип авто' },
  { type: 'text', text: 'Компания' },
  { type: 'text', text: 'Комментарий' },
  { type: 'text', text: 'Сумма оплаты'},
  { type: 'text', text: 'Тип оплаты' },
  { type: 'text', text: 'Тариф' },
]
