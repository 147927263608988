import { ICellProps } from './../../../../types/table'

export const titles: ICellProps[] = [
  { type: 'text', text: 'Дата' },
  { type: 'text', text: 'Название' },
  { type: 'text', text: 'Грузовой отсек' },
  { type: 'text', text: '' },
  { type: 'text', text: 'Мин. Заказ' },
  { type: 'text', text: 'Стоимость (час)' },
]
