import React, { useState } from 'react'

import { IInputToggleProps } from './types'

import {Link} from "react-router-dom";


const PasswordInputToggle: React.FC<IInputToggleProps> = ({
   name,
   type = 'number',
   isCorrect = true,
   label,
   prefix,
   suffix,
   value,
   changeHandler = () => { }
   }) => {

    const [isActive, toggleActive] = useState<boolean>(false)

    const Events = {
        activeHandler: () => {
            if (isCorrect) toggleActive(!isActive)
        }
    }

    return (
        <div className='input-toggle'>

            {label ? (
                <h6 className='input-toggle-label'>
                    {label}
                </h6>
            ) : null}

            <div className='input-toggle-wrapper'>

                {prefix ? (
                    <span>{prefix}</span>
                ) : null}

                { isActive ?
                <input
                    name={name}
                    type='text'
                    className={`
            input-show 
            ${isActive && 'input-show--change'}
            input-show--${type}
          `}
                    disabled={!isActive}
                    value={value}
                    onChange={changeHandler}
                /> : <span>xxxx &nbsp;</span> }

                {suffix ? (
                    <span>{suffix}</span>
                ) : null}

            </div>

            <Link
                className='link-default'
                to="#"
                onClick={Events.activeHandler}
            >
                Изменить пароль
            </Link>

        </div>
    )
}


export default PasswordInputToggle
