import React from 'react'

import TableWrapper from '../../../../components/Table/Table'

import Track from './Track'
import NewColor from './NewColor'

import { ITableProps } from './types'

import { titles } from './data'



const Table: React.FC<ITableProps> = ({
    items,
    isCreate
}) => {

    return (
        <TableWrapper
            header={{
                type: 'colors',
                cells: titles
            }}
        >

            {isCreate ? (
                <NewColor />
            ) : null}

            {items.map(item => (
                <Track
                    key={item._id}
                    type='colors'
                    data={item}
                />
            ))}

        </TableWrapper>
    )
}



export default Table