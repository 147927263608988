import React, { useState, useEffect, Fragment } from 'react'

import * as Buttons from '../../../../components/Buttons'
import * as Form from '../../../../components/Form'
import * as Page from '../../../../components/Page'

import { IMainBlockProps } from './types'
import Modal from '../Modal'
import { TModalContent } from '../../types'
// import Dropdown from "../../../../components/Form/Dropdown";


const MainBlock: React.FC<IMainBlockProps> = ({
  tariffs,
  tariff,
  data,
  images,
  confirmBtn,
  changeHandler,
  rejectHandler,
  confirmHandler,
  deactivateHandler,
  saveHandler,
  downloadHandler,
}) => {

  const [modal, setModal] = useState<TModalContent>(null)

  const Events = {
    setModalHandler: () => {
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
      setModal('slider')
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  }


  return (
    <Fragment>
      <Modal
        type={modal}
        data={data}
        images={images}
        clearModal={Events.clearModal}
      />

      <div className='docs-block'>
        <div className='flex flex--between flex--top'>
          <Page.Images
            images={images}
            clickHandler={Events.setModalHandler}
          />

          <div>
            {data?.status !== 'active' ? (confirmBtn) ? (
              <Buttons.Default
                type='button'
                title='Подтвердить всё'
                buttonType='green'
                buttonHandler={confirmHandler}
              />
            ): null : null}

            {data?.status !== 'active' ? (
            <Buttons.Default
              type='button'
              title='Отклонить'
              buttonType='delete'
              buttonHandler={rejectHandler}
            /> ) : null}

            {data?.status !== 'deactivated' ? (
              <Buttons.Default
                type='button'
                title='Деактивировать'
                buttonType='delete'
                buttonHandler={deactivateHandler}
              />
            ) : null}
            {(tariffs[0]) ?
            <Form.Dropdown
              items={tariffs}
              item={tariff}
              changeHandler={changeHandler}
            /> : 'Нет тарифов для даного автомобиля!'}
            <Buttons.Default
                type='button'
                title='Сохранить'
                buttonType='green'
                buttonHandler={saveHandler}
            />
            <Buttons.Default
                type='button'
                title='Загрузить документы'
                buttonType='green'
                buttonHandler={downloadHandler}
            />
          </div>
        </div>

        <div className='docs-column'>
          <h4 className='docs-title'>
            Автомобиль
          </h4>

          <Form.DetailField
            label='Марка машины:'
            value={data?.mark.title}
          />
          <Form.DetailField
            label='ГОС номер:'
            value={data?.car_number}
          />
          <Form.DetailField
            label='Цвет:'
            value={data?.color.ru}
          />
          <Form.DetailField
            label='Дата регистрации:'
            value={new Date(data?.createdAt!).toLocaleDateString()}
          />

          <div className='docs-column'>

            <h4 className='docs-title'>
              Габариты:
            </h4>

            <Form.DetailField
              label='ДхШхВ'
              value={`${data?.size.length}x${data?.size.weight}x${data?.size.height}`}
            />

            <Form.DetailField
              label='Грузоподъемность (т):'
              value={`${data?.size.liftingCapacity!}`}
            />
            <Form.DetailField
              label='Вес машины(т):'
              value={`${data?.size.weight}`}
            />
          </div>
        </div>


      </div>
    </Fragment>
  )
}
export default MainBlock
