import React, { useContext, useState, Fragment } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { useHttp } from '../../../../hooks/http.hook'

import { config } from '../../../../config/config'

import { useMesasge } from "../../../../hooks/message.hook";

import { IFineModalProps, IForm } from './types'
import {useParams} from "react-router-dom";
import {IDriver} from "../../../Drivers/types";
import Track from "../Driver/Track";

const DriverModal: React.FC<IFineModalProps> = ({
  data,
  clearModal
}) => {


  const { id } = useParams()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const message = useMesasge();

  const [changes, setChanges] = useState<any[]>([]);

  const [drivers, setDrivers] = useState<IDriver[]>()

  const [form, setForm] = useState<IForm>({
    search: ''
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    cancelHandler: () => {
      clearModal()
    },
    searchHandler: async () => {
        const responce: any = await request(
            `${config.API}/drivers/search/${(form.search) ? form.search : '2'}`, 'GET', null, {
                Authorization: token
            });

         if (responce) {
            setDrivers(responce?.data);
         }
    },
    addHandler: async () => {
       // console.log(data);
       /* const responce = await request(
            `${config.API}/users/${id}/addDrivers`, 'PATCH', {
                ids: changes
           }, {
                Authorization: token
            }) */
       const responce = await request(
           `${config.API}/users/${id}/invite`, 'POST', {
           driverIds: changes
       }, {
           Authorization: token
       })

       if (responce) {
        clearModal();
        window.location.reload()
       } else {
        message('Не все поля заполнены!');
       }
    }
  }


  return (
    <Fragment>

      <h4 className='page-modal-title'>
        Поиск водителя
      </h4>

      <Form.Input
        type='text'
        label='Поиск:'
        margin='bottom'
        name='search'
        placeholder={'Имя пользователя/Номер телефона'}
        value={form.search}
        inputHandler={Events.inputHandler}
        pressHandler={Events.searchHandler}
      />
      <Buttons.Default buttonHandler={Events.searchHandler} type='button' title='Искать' />

        {(drivers && drivers[0] && form.search !== '') ? drivers.map(item => (
            <Track
                key={item._id}
                type='drivers-search'
                changes={changes}
                setChanges={setChanges}
                data={item}
            />
        )) : <b>Не найдено ниодного пользователя!</b>}

      <div className='page-modal-btns'>
        <Buttons.Large
          type='cancel'
          title='Отмена'
          buttonHandler={Events.cancelHandler}
        />

        <Buttons.Large
          type='ok'
          title='Добавить'
          buttonHandler={Events.addHandler}
        />
      </div>

    </Fragment>
  )
}
export default DriverModal
