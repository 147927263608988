import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import * as Form from '../../../components/Form'

import { IPersonEdit } from './types'


const PersonEdit: React.FC<IPersonEdit> = ({
  _id,
  type,
  login,
  isLogin,
  email,
  password,
  isPassword,
  isEmail,
  children,
  disabledButton,
  inputHandler,
  saveHandler,
  createHandler
}) => {

  return (
    <div className='setting-edit settings-input-box'>

      <div className='settings-edit-login'>
        <span className='preffix'>
          Логин:
        </span>

        {type === 'create' ? (
          <Form.Input
            name='login'
            type='text'
            value={login}
            //@ts-ignore
            inputHandler={inputHandler}
           // changeHandler={inputHandler}
          />
        ) : (
            <Form.InputToggle
              name='login'
              type='text'
              isCorrect={isLogin}
              value={login}
              //@ts-ignore
              changeHandler={inputHandler}
            />
          )}

      </div>

      <div className='settings-edit-login'>
        <span className='preffix'>
          Пароль:
        </span>

        {type === 'create' ? (
          <Form.Input
            name='password'
            type='text'
            value={(password as string)}
            //@ts-ignore
            inputHandler={inputHandler}
            // changeHandler={inputHandler}
          />
        ) : (
            <Fragment>
                <Form.PasswordInputToggle
                    name='password'
                    type='text'
                    isCorrect={true}
                    value={(password as string)}
                    //@ts-ignore
                    changeHandler={inputHandler}
                />
            </Fragment>
          )}
      </div>

        {type === 'create' ? (  <div className='settings-edit-login'>
        <span className='preffix'>
          Почта:
        </span>
                <Form.Input
                    name='email'
                    type='text'
                    value={(email as string)}
                    //@ts-ignore
                    inputHandler={inputHandler}
                    // changeHandler={inputHandler}
                />
        </div>
        ) : null }
      <div className='settings-edit-children'>
        {children}
      </div>

      {createHandler ? (
        <button
          className='btn'
          onClick={createHandler}
          disabled={isEmail}
        >
          Создать
        </button>
      ) : (
          <button
            className='btn'
            onClick={saveHandler}
            disabled={disabledButton}
          >
            Сохранить изменения
          </button>
        )}

    </div>
  )
}


export default PersonEdit
