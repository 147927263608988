import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import * as Buttons from '../Buttons'

import { ICellProps } from '../../types/table'


const Cell: React.FC<ICellProps> = ({
  text,
  status = 'active',
  paymentApproved = true,
  headerData,
  modal,
  modalText,
  style,
  type = 'text',
  textColor = 'black',
  textWeight = 'normal',
  direction,
  cars,
  driver,
  reason,
  linkTitle,
  linkTo = '/',
  linkHandler = () => { },
  buttonTitle,
  buttonType = 'default',
  buttonHandler = () => { },
  renderLeftIcon = () => <Fragment><img src="../../images/file.svg" /></Fragment>,
  renderRightIcon,
}) => {


// console.log(textWeight)

  if (type === 'header') {

    if (headerData?.name) {
      return (
        <div className='table-cell table-cell--header'>
          {text}

          <div className='table-cell--header-box'>
            <button
              data-sort={`${headerData.name}-asc`}
              onClick={buttonHandler}
            >
              asc
          </button>

            <button
              data-sort={`${headerData.name}-desc`}
              onClick={buttonHandler}
            >
              desc
          </button>
          </div>
        </div>
      )
    }

    return (
      <div className='table-cell table-cell--header'>
        {text}
      </div>
    )
  }

  if (type === 'link') {
    return (
      <div
        className={`${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'} ${textColor} ${textWeight}`}
        style={style}
      >
        <p>{text}</p>

        <Link to={linkTo}>{linkTitle}</Link>
      </div>
    )
  }

  if (type === 'documents') {
      // console.log(cars)
      return (
          <div className={`
          ${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'}
        `}>

              {cars?.map((car, index) => {
                 return (car.status !== 'active') ? <img key={index} className='drivers-document-img' src={require('../../images/file1.svg')} alt=""/> :
                      <img key={index} className='drivers-document-img' src={require('../../images/file.svg')} alt=""/>
              })

              }

          </div>
      );
  }

  if (type === 'column' || (type !== 'button' && buttonTitle)) {

    return (
      <div className={`
        ${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'}
        ${type === 'column' ? 'table-cell--column' : ''}
        ${textColor} 
        ${textWeight} 
      `}
        style={style}
      >

        {linkTitle ? (
          <Link to={linkTo}>{linkTitle}</Link>
        ) : null}

        {buttonTitle ? (
          <Buttons.Table
            type={(buttonType as 'delete')}
            title={buttonTitle}
            buttonHandler={buttonHandler}
          />
        ) : null}
      </div>
    )
  }

  if (type === 'cars') {
    return (
      <div className={`${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'} table-cell--cars ${textColor} ${textWeight} `}>
        {cars?.map((car, index) => (
          <Fragment key={index}>
            <Link
              key={car._id}
              className='normal'
              to={`/drivers/docs/${car._id}`}
            >{car.mark.title}</Link>

            {/*cars.length - 1 !== index && <span>,&nbsp;</span>*/}
          </Fragment>
        ))
        }
      </div >
    )
  }

  if (type === 'button') {
    return (
      <div className={`${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'}`}>
        <button
          className='table-btn table-btn--default'
          onClick={buttonHandler}
        >
          {!reason ? buttonTitle : <img src={require('../../assets/baseline_person_outline.png')} alt={'None'} />}
        </button>
      </div>
    )
  }

  return (
    <div
      className={`${(status === 'active') ? (paymentApproved) ? 'table-cell' : 'table-cell-notpay' :'table-cell-active'}
      ${direction === 'column' ? 'table-cell--column' : ''}
      ${textColor} 
      ${textWeight}
      `}
      style={style}
    >
      {text}

      {modal ? (
        <div className={`${(status === 'active') ? (paymentApproved) ? 'table-cell-modal' : 'table-cell-notpay-modal' : 'table-cell-active-modal'}`}>
          {modalText}
        </div>
      ) : null}

      {renderRightIcon ? (
        renderRightIcon()
      ) : null}
    </div>
  )
}

export default Cell
