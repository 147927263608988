import React from 'react'

const NotifBar: React.FC = () => {

  return (
    <aside className='notif-bar'>

    </aside>
  )
}
export default NotifBar