import React, { useContext } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import * as Contexts from '../contexts'
import * as Page from '../components/Page'

import * as Auth from '../pages/Auth'
import * as Consumers from '../pages/Consumers'
import * as Drivers from '../pages/Drivers'
import * as Finance from '../pages/Finance'
import * as Orders from '../pages/Orders'
import * as Settings from '../pages/Settings'
import * as Tariffs from '../pages/Tariffs'
import * as Users from '../pages/Users'
import * as Companies from '../pages/Companies'
import * as Withdraw from '../pages/Withdraw'
import * as Statistics from '../pages/Statistics'
import * as HaveNotPermission from '../pages/HaveNotPermission'

const Router: React.FC = () => {

    const { isAuthenticated, ready, rules, type } = useContext(Contexts.AuthContext)
    let rule

    if (rules) {
        for (const [key, value] of Object.entries(rules)) {
            if (value) {
                rule = key
            }
        }
    }

    if (!ready) {
        return <Page.Preloader />
    }

    if (isAuthenticated) {
        return (
            <Switch>
                <Route path='/orders' exact>
                    <Orders.IndexPage />
                </Route>

                <Route path='/orders/:id' exact>
                    <Orders.DetailPage />
                </Route>

                <Route path='/consumers' exact>
                    <Consumers.IndexPage />
                </Route>

                <Route path='/consumers/:id' exact>
                    <Consumers.DetailPage />
                </Route>

                <Route path='/drivers' exact>
                    <Drivers.IndexPage />
                </Route>

                <Route path='/drivers/:id' exact>
                    <Drivers.DetailPage />
                </Route>

                <Route path='/drivers/history/:id' exact>
                    <Drivers.HistoryPage />
                </Route>

                <Route path='/drivers/fines/:id' exact>
                    <Drivers.FinePage />
                </Route>

                <Route path='/drivers/docs/:id' exact>
                    <Drivers.DocsPage />
                </Route>

                <Route path='/finance' exact>
                    <Finance.IndexPage />
                </Route>

                <Route path='/tariffs' exact>
                    <Tariffs.IndexPage />
                </Route>

                <Route path='/tariffs/cars' exact>
                    <Tariffs.CarsPage />
                </Route>

                <Route path='/tariffs/colors' exact>
                    <Tariffs.ColorsPage />
                </Route>

                <Route path='/tariffs/:id/history' exact>
                    <Tariffs.HistoryPage />
                </Route>

                <Route path='/settings' exact>
                    <Settings.IndexPage />
                </Route>

                <Route path='/users' exact>
                    <Users.IndexPage />
                </Route>

                <Route path='/users/:id' exact>
                    <Users.DetailPage />
                </Route>

                <Route path='/companies' exact>
                    <Companies.IndexPage />
                </Route>

                <Route path='/withdraw' exact>
                    <Withdraw.IndexPage />
                </Route>

                <Route path='/statistics' exact>
                    <Statistics.IndexPage />
                </Route>

                <Route path='/perm_error' exact>
                    <HaveNotPermission.IndexPage />
                </Route>

                {type === 'Admin' || rules?.orders ?
                    <Redirect to='/orders' /> : <Redirect to={'/' + rule} />
                }
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path='/' exact>
                <Auth.AuthPage />
            </Route>

            <Redirect to='/' />
        </Switch>
    )
}

export default Router
