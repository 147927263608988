import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'

import { useHttp } from '../../../hooks/http.hook'

import { config } from '../../../config/config'

import { IHeader } from './types'
import PersonEdit from './PersonEdit'
import {EVars} from "../../../contexts/types";


const Header: React.FC<IHeader> = ({
  data,
  addHandler
}) => {

  const { token } = useContext(Contexts.AuthContext)
  const { notif, setNotif } = useContext(Contexts.UserContext)

  const { loading, request } = useHttp()

  const [isLogin, toggleLogin] = useState<boolean>(true)
  const [form, setForm] = useState({
    login: '',
    notif: notif
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    notifHandler: () => {
     // console.log(form.notif);
      setForm({ ...form, notif: !form.notif })
    },
    saveHandler: () => {
      setNotif(form.notif);
     // (!form.notif) ? localStorage.setItem('notif', String(form.notif)) : localStorage.removeItem('notif');
     // console.log(!!localStorage.getItem('notif'));
      Callbacks.Save()
    }
  }

  const Callbacks = {
    Save: async () => {
      try {
         const response = await request(`${config.API}/profile`, 'PATCH', { ...form }, {
           Authorization: token
         })
        console.log(response);
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    setForm({ ...form, login: data.login, notif: /*!!(localStorage.getItem('notif'))*/(data.notif as boolean) })
  }, [data])

  useEffect(() => {
    if (form.login.length >= 4) toggleLogin(true)
    else toggleLogin(false)
  }, [form.login])


  return (
    <div className='filter-bar filter-bar--flex-start'>
      <div className='settings-header--left'>

        <PersonEdit
          _id={data._id}
          login={form.login}
          isLogin={isLogin}
          disabledButton={loading}
          inputHandler={Events.inputHandler}
          saveHandler={Events.saveHandler}
        >

          <label className='label' htmlFor='settings-notif'>
            <input
              name='notif'
              className='checkbox'
              type='checkbox'
              id='settings-notif'
              checked={form.notif}
              onChange={Events.notifHandler}
            />
                Показывать уведомления
            </label>

        </PersonEdit>

      </div>

      <Buttons.Default
        type='button'
        title='Новый аккаунт'
        buttonType='plus'
        buttonHandler={addHandler}
      />
    </div>
  )
}

export default Header
