import React, {createContext, useContext, useEffect, useState} from 'react'
import {IWSContext} from './types'
import {useNotification} from '../hooks/notification.hook';
import * as Contexts from '../contexts'
import {config} from "../config/config";
import {useHttp} from "../hooks/http.hook";


const WSContext = createContext<IWSContext>({
  messages: [],
  setMessages: () => {}
})

export default WSContext


export const WSContextProvider: React.FC = ({ children }) => {

  const ws = new WebSocket('ws://localhost:3000/ws')

  const { token } = useContext(Contexts.AuthContext)

  const mess = useNotification()

  const [messages, setMessages] = useState<any[]>([])
  const { request } = useHttp()

  const { notif } = useContext(Contexts.UserContext)

  let notifications: any;

  const Events = {
    FetchNotifications: async () => {
      try {
        return await request(`${config.API}/notifications`, 'GET', null, {
           Authorization: token
        });
      } catch (e) {
        console.log(e);
      }
    },
    onOpen: () => {
      ws.onopen = () => {
        console.log('WS is connected')
      }
    },
    onMessage: (evt: any) => {
      // console.log(evt)

     // const message = JSON.parse(evt.data)
      const message = evt.data;
      console.log(message[0].message);
      // const message = messages[0];
      setMessages([...messages, message])
      console.log(messages);
      mess('oregjoireg', message[0].message)
    },
    onClose: () => {
      setMessages([])
      ws.onclose = () => {
       // console.log('WS is closed')
      }
    },
    onError: () => {
      ws.onerror = (e) => {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (/*messages[0] &&*/ notif) {
      /* setTimeout(async () => {
      /* notifications = await Events.FetchNotifications()
        Events.onOpen()
        Events.onMessage(notifications)
        Events.onClose()
     }, 30000)*/
    }
  }, [/*Events*/])


  return (
    <WSContext.Provider value={{
     setMessages, messages
    }}>
      {children}
    </WSContext.Provider>
  )
}


