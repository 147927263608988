import React from 'react'

import { Link } from 'react-router-dom'

import * as Buttons from '../../../components/Buttons'
import { IHeaderProps } from './types'


const Header: React.FC<IHeaderProps> = ({
  addHandler
}) => {

  return (
    <div className='filter-bar filter-bar--flex-end'>

      <div>
        <div>
          <Link
            className='link-default'
            to='/tariffs/colors'
          >
            Список допустимых цветов автомобилей
          </Link>
        </div>

        <div>
          <Link
            className='link-default'
            to='/tariffs/cars'
          >
            Список допустимых марок автомобилей
          </Link>
        </div>
      </div>

      <div className='filter-bar-right'>
        <Buttons.Default
          type='button'
          title='Новый тип'
          buttonType='plus'
          buttonHandler={addHandler}
        />
      </div>
    </div>
  )
}


export default Header