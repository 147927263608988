import React from 'react'

import { useHistory } from 'react-router-dom'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {

  const history = useHistory()

  const Events = {
    toProfile: () => {
      history.push(`/consumers/${data._id}`)
    }
  }

  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        textWeight='bold'
        text={data.fullName}
      />

      <Cell
        text={new Date(data.createdAt).toLocaleDateString()}
      />

      <Cell
        textWeight='bold'
        text={data.phone}
      />

      <Cell
        text={data.status}
      />

      <Cell
        textWeight='bold'
        text={(data.ordersCount) ? `${data.ordersCount}` : '0'}
      />

      <Cell
        text={(data?.total) ? `Всего: ${data?.total} грн` : `Всего: 0 грн`}
        modal
        modalText={
            `Наличкой: ${(data?.cash) ? data?.cash : 0} грн
             Онлайн: ${(data?.online) ? data?.online : 0} грн`
        }
      />

      <Cell
        type='button'
        reason={'Smth'}
        buttonTitle='Смотреть профиль'
        buttonType='default'
        buttonHandler={Events.toProfile}
      />

    </div>
  )
}

export default Track
