import React, {useState, useEffect, useContext} from 'react'

import {useHistory} from 'react-router-dom'

import * as Table from '../../../../components/Table'

import { ITrackProps } from './types'
import { ICompany } from '../../types'

import { config } from '../../../../config/config'
import * as Contexts from "../../../../contexts";
import {useHttp} from "../../../../hooks/http.hook";
import { useMesasge } from "../../../../hooks/message.hook";


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {

  const history = useHistory()

  const message = useMesasge()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [isCorrect, toggleCorrect] = useState({
    title: true,
    description: true,
    phone: true
  })
  const [form, setForm] = useState<ICompany>({
    title: data.title,
    description: data.description,
    phone: data.phone
  })

  const Events = {
    changeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    deleteHandler: () => {
      const response = window.confirm('Delete?')
      if (response) Callbacks.Delete()
    },
    saveHandler: () => {
      const response = window.confirm('Save?')
      if (response) Callbacks.Save()
    },
    toProfile: () => {
      history.push(`/users/${data._id}`)
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
         await request(`${config.API}/movers/${data._id}`, 'DELETE', null, {
             Authorization: token
         })

        await window.location.reload(false)
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const regexPhone = /^([\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
        if (!regexPhone.test(form.phone)) {
          message('Не валидный номер телефона!')
        } else {
          const response: any = await request(`${config.API}/movers/${data._id}`, 'POST', form, {
            Authorization: token
          })

          if (response) await window.location.reload(false)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (form.title.length > 0) toggleCorrect({ ...isCorrect, title: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.title])

  useEffect(() => {
    if (form.description.length > 0) toggleCorrect({ ...isCorrect, description: true })
    else toggleCorrect({ ...isCorrect, description: false })
  }, [form.description])

  useEffect(() => {
    if (form.phone.toString().length > 0) toggleCorrect({ ...isCorrect, phone: true })
    else toggleCorrect({ ...isCorrect, phone: false })
  }, [form.phone])


  return (
    <div className={`table-track table-track--${type}`}>

      <Table.MainCell
        name='title'
        isCorrect={isCorrect.title}
        value={form.title}
        events={Events}
      />

      <Table.InputCell
        name='phone'
        isCorrect={isCorrect.phone}
        value={form.phone}
        changeHandler={Events.changeHandler}
      />

      <Table.TextareaCell
        isCorrect={isCorrect.description}
        value={form.description}
        changeHandler={Events.changeHandler}
      />

    </div>
  )
}

export default Track
