import React from 'react'
import * as Form from '../Form'
import { IInputMultiProps } from './types'


const InputMulti: React.FC<IInputMultiProps> = ({
  value,
  values,
  inputHandler,
  pressHandler,
  removeHandler
}) => {

  return (
    <div className='input-multi-wrapper'>
      {values.map((value, index) => (
        <span
          key={value + Date.now()}
          className='input-multi-value'
          onClick={() => removeHandler(index)}
        >
          {value}
        </span>
      ))}

      <Form.Input
        name='mark'
        value={value}
        inputHandler={inputHandler}
        pressHandler={pressHandler}
      />
    </div>
  )
}

export default InputMulti