import React, { useContext, useState, useEffect } from "react";

import * as Contexts from "../../contexts";
import * as Table from "../../components/Table";
import * as Page from "../../components/Page";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks/http.hook";

import { IFilter } from "../../types/filter";
import { IPagination } from "../../types";
import { IOrder } from "./types";

import { ordersDummy } from "./data";
import { useHistory } from "react-router-dom";
import { config } from "../../config/config";
import * as Form from "../../components/Form"; // waiting for API

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [xslsData, setXSLSData] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "",
    orderNumber: "",
    sortOrder: "asc",
    dateUse: false,
    dateFrom: "",
    dateTo: "",
  });
  const history = useHistory();
  let address: any = [];
  let prefix: string = "";

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      // const sortBy = e.target.value;
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1] ?? "asc";
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    timeHandler: () => {
      setFilter({ ...filter, dateUse: !filter.dateUse });
    },
    sortHandler: (e: React.MouseEvent<HTMLButtonElement>) => {
      const { sort } = (e.target as HTMLButtonElement).dataset;
      const sortBy = (sort as string).split("-")[0];
      const sortOrder = (sort as string).split("-")[1] ?? "asc";
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.FetchOrders(1);
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
    searchHandler: () => {
      Callbacks.FetchOrders(1);
    },
  };

  const Callbacks = {
    FetchOrders: async (page?: number) => {
      try {
        const response: any = await request(
          `${config.ordersAPI}/?page=${page ? page : pagination.page}&perPage=${
            pagination.perPage
          }&sortOrder=${filter.sortOrder}${
            filter.sortBy === "sum"
              ? `&sortBy=${filter.sortBy}`
              : `&status=${filter.sortBy}`
          }&_index=${filter.orderNumber}&createdAt[]=${
            filter.dateUse ? filter.dateFrom : ""
          }&createdAt[]=${filter.dateUse ? filter.dateTo : ""}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        const responseSXLS: any = await request(
          `${config.ordersAPI}/?page=${1}&perPage=${
            response.data?.pagination?.total
          }&createdAt[]=${filter.dateUse ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateTo
          }&sortOrder=${filter.sortOrder}${
            filter.sortBy === "sum"
              ? `&sortBy=${filter.sortBy}`
              : `&status=${filter.sortBy}`
          }`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];
        let sum: number = 0;

        if (!response.data) {
          history.push("/perm_error");
        }

        if (response.data.data) {
          response.data.data.forEach((item: any, key: number) => {
            item.time =
              new Date(item.time).toLocaleTimeString() +
              " " +
              new Date(item.time).toLocaleDateString();
            address[key] = "";
            if (item.status === "searchDriver") {
              item.status = "Поиск водителя";
            } else if (item.status === "accepted") {
              item.status = "Подтвержден";
            } else if (item.status === "driverInGoing") {
              item.status = "Водитель в пути";
            } else if (item.status === "inProgress") {
              item.status = "В процессе";
            } else if (item.status === "waiting_for_payment") {
              item.status = "Ожидает оплаты";
            } else if (item.status === "done") {
              item.status = "Выполнен";
            } else if (item.status === "canceledByClient") {
              item.status = "Отменен клиентом";
            } else if (item.status === "canceledByAdmin") {
              item.status = "Отменен администратором";
            } else if (item.status === "expiredByTime") {
              item.status = "Просрочен";
            } else if (item.status === "paymentError") {
              item.status = "Ошибка оплаты";
            } else if (item.status === "paymentProcessing") {
              item.status = "В процессе оплаты";
            } else if (item.status === "waiting_for_approving") {
              item.status = "Ожидает подтверждения";
            }

            if (item.typeOfPayment === "online") {
              item.typeOfPayment = "Карта";
            } else {
              item.typeOfPayment = "Наличные";
            }

            item.coordinates.forEach((item1: any, key1: number) => {
              prefix = key1 > 0 ? "->" : "";
              address[key] += prefix + item1.displayTitle;
            });
            item.distance = address[key];
          });

          responseSXLS.data.data.forEach((resp: any, key1: any) => {
            resp.time =
              new Date(resp.time).toLocaleTimeString() +
              " " +
              new Date(resp.time).toLocaleDateString();
            address[key1] = "";
            if (resp.status === "searchDriver") {
              resp.status = "Поиск водителя";
            } else if (resp.status === "accepted") {
              resp.status = "Подтвержден";
            } else if (resp.status === "driverInGoing") {
              resp.status = "Водитель в пути";
            } else if (resp.status === "inProgress") {
              resp.status = "В процессе";
            } else if (resp.status === "waiting_for_payment") {
              resp.status = "Ожидает оплаты";
            } else if (resp.status === "done") {
              resp.status = "Выполнен";
            } else if (resp.status === "canceledByClient") {
              resp.status = "Отменен клиентом";
            } else if (resp.status === "canceledByAdmin") {
              resp.status = "Отменен администратором";
            } else if (resp.status === "expiredByTime") {
              resp.status = "Просрочен";
            } else if (resp.status === "paymentError") {
              resp.status = "Ошибка оплаты";
            } else if (resp.status === "paymentProcessing") {
              resp.status = "В процессе оплаты";
            } else if (resp.status === "waiting_for_approving") {
              resp.status = "Ожидает подтверждения";
            }

            if (resp.typeOfPayment === "online") {
              resp.typeOfPayment = "Карта";
            } else {
              resp.typeOfPayment = "Наличные";
            }

            resp.coordinates.forEach((item1: any, key2: number) => {
              prefix = key2 > 0 ? "->" : "";
              address[key1] += prefix + item1.displayTitle;
            });
            resp.distance = address[key1];
            Arr.push({
              number: resp.index,
              client: resp.client?.firstName + " " + resp.client?.lastName,
              driver: resp.driver?.firstName + " " + resp.driver?.lastName,
              date: new Date(resp.createdAt).toLocaleDateString(),
              status: resp.status,
              distance: resp.distance,
              tariff: resp.tariff?.title,
              // time: resp?.time,
              price: resp?.sum,
            });
            sum += resp?.sum;
          });
          Arr.push({
            sumOfAllOrders: sum,
            countOrders: Arr.length,
          });
          setXSLSData(Arr);
          setOrders(response.data.data);
        }

        if (response.data.pagination)
          setPagination({
            ...pagination,
            totalCount: response.data.pagination.total,
            page: response.data.pagination.page,
          });
        else setPagination({ ...pagination, totalCount: 0 });

        // setOrders(ordersDummy)
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchOrders();
  }, [filter.sortBy, filter.sortOrder, pagination.page, filter.dateUse]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <div className="page-wrapper">
      <Page.Filter
        agePicker
        ordersStatusSelect
        orderInput
        sumSelect
        form={filter}
        events={Events}
      >
        <Form.DetailField
          type="filter"
          label="Заказов"
          value={`${pagination.totalCount}`}
        />

        <CSV.ExcelUpload csvData={xslsData} fileName={"orders"} />
      </Page.Filter>

      <Table.OrdersTable items={orders} />

      <Page.Pagination
        linkTo="/orders"
        pagination={pagination}
        paginateHandler={Events.paginateHandler}
      />
    </div>
  );
};

export default IndexPage;
