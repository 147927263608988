import React, { useState } from 'react'

import { ISelectToggleProps } from './types'

import Edit from '../../assets/edit.png'
import Check from '../../assets/check.png'


const SelectToggle: React.FC<ISelectToggleProps> = ({
  name,
  value,
  options = [],
  changeHandler = () => { }
}) => {

  const [isActive, toggleActive] = useState<boolean>(false)

  const Events = {
    activeHandler: () => {
      toggleActive(!isActive)
    }
  }

  return (
    <div className='input-toggle'>

      <div className='input-toggle-wrapper'>

        <select
          name={name}
          value={value}
          disabled={!isActive}
          onChange={changeHandler}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>

      </div>

      <img
        className='btn-table-edit'
        alt={!isActive ? 'Edit' : 'Submit'}
        src={!isActive ? Edit : Check}
        onClick={Events.activeHandler}
      />

    </div>
  )
}


export default SelectToggle