import React, { Fragment } from 'react'

import * as Page from '../../../components/Page'

import DriverModal from './Modal/DriverModal'

// import { IModalProps } from './types'
// mport { IDriver, IDriverCar } from '../types'


const Modal: React.FC<any> = ({
  type,
  data,
  images = [],
  clearModal
}) => {

  const Events = {
    contentHandler: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
    }
  }

  return (
    <Fragment>
      <Page.Modal
        isActive={type}
        clearModal={clearModal}
      >
        <div
          className={`page-modal-container page-modal-container--${type}`}
          onClick={Events.contentHandler}
        >

          {type === 'driver' ? (
            <DriverModal
              data={(data as any)}
              clearModal={clearModal}
            />
          ) : null}

        </div>
      </Page.Modal>
    </Fragment>
  )
}

export default Modal
