import React from 'react'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {

  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        textWeight='bold'
        text={new Date(data.createdAt).toLocaleDateString()}
      />

      <Cell
        textWeight='bold'
        text={data.name}
      />

      <Cell
        textWeight='bold'
        text={`${data.app_per_day}`}
      />

    </div>
  )
}

export default Track
