import React from 'react'

import Cell from './Cell'

import { IHeaderProps } from './types'



const Header: React.FC<IHeaderProps> = ({
  type,
  cells,
  sortHandler
}) => {


  return (
    <header className={`table-header table-header--${type}`}>
      {cells.map(cell => (
        <Cell
          key={cell.text}
          type='header'
          headerData={cell.headerData}
          text={cell.text}
          buttonHandler={sortHandler}
        />
      ))}
    </header>
  )
}

export default Header
