import React from 'react'

import * as Form from '../../components/Form'

import { ITextareaCellProps } from './types'



const CustomCell: React.FC<ITextareaCellProps> = ({
  value,
  isCorrect,
  changeHandler
}) => {

  return (
    <div className='table-cell table-cell--top'>
      <Form.TextareaToggle
        type='table'
        name='description'
        isCorrect={isCorrect}
        value={value}
        changeHandler={changeHandler}
      />
    </div>
  )
}
export default CustomCell