import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import * as Page from '../../components/Page'
import * as Contexts from '../../contexts'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { TTariff } from './types'

import { config } from '../../config/config'



const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)

    const { loading, request } = useHttp()

    const [tariffs, setTariffs] = useState<TTariff[]>([])
    const [isCreate, toggleCreate] = useState<boolean>(false)

    const Events = {
        addHandler: () => {
            toggleCreate(!isCreate)
        }
    }
    const history = useHistory();

    const Callbacks = {
        FetchTariffs: async () => {
            try {
                const response: any = await request(`${config.API}/tariffs`, 'GET', null, {
                    Authorization: token
                })

                if (!response.data) {
                    history.push('/perm_error');
                }

                setTariffs(response.data)
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.FetchTariffs()
    }, [])


    if (loading) {
        return (
            <Page.Preloader />
        )
    }

    return (
        <div className='page-wrapper'>
            <Components.Header
                addHandler={Events.addHandler}
            />

            <Components.Table
                items={tariffs}
                isCreate={isCreate}
            />
        </div>
    )
}



export default IndexPage
