import React from 'react'

import { ILargeProps } from './types'


const Large: React.FC<ILargeProps> = ({
  type,
  title,
  buttonHandler = () => { }
}) => {

  return (
    <button
      className={`btn btn--large btn--large--${type}`}
      onClick={buttonHandler}
    >

      <span>{title}</span>

    </button>
  )
}


export default Large