import React, { useContext } from 'react'

import {
  Slide,
  Slider,
  ButtonNext,
  ButtonBack,
  CarouselProvider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { ISliderProps } from './types'
import { AuthContext } from '../../contexts'



const Corousel: React.FC<ISliderProps> = ({
  images = []
}) => {

  const { token } = useContext(AuthContext)

  return (
    <CarouselProvider
      naturalSlideWidth={750}
      naturalSlideHeight={750}
      infinite
      totalSlides={images.length}
    >
      <Slider>
        {images.map((image, index) => (
          <Slide key={index} index={index}>
            <img
              alt='doc'
              src={image + `/?token=${token ? token : ''}`}
            />
          </Slide>
        ))}
      </Slider>

      <ButtonBack>Back</ButtonBack>
      <ButtonNext>Next</ButtonNext>

    </CarouselProvider>
  )
}
export default Corousel