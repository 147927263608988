import { ICellProps } from './../../../../types/table'

export const titles: ICellProps[] = [
  { type: 'text', text: 'Название компании' },
  { type: 'text', text: 'Логин' },
  { type: 'text', text: 'Пароль' },
  { type: 'text', text: 'Почта' },
  { type: 'text', text: 'Описание компании' },
  { type: 'text', text: 'Водители' },
  { type: 'text', text: 'Баланс' },
  { type: 'text', text: '' },
]
