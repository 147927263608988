import React, { useContext, useState } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'
import * as Form from '../../../../components/Form'

import { config } from '../../../../config/config'
import { useHttp } from '../../../../hooks/http.hook'



const NewCar: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [value, setValue] = useState<string>('')
  const [values, setValues] = useState<string[]>([])

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setValue('')
        setValues([...values, value])
      }
    },
    removeHandler: (id: number) => {
      setValues(values.filter((item, index) => index !== id))
    },
    createHandler: () => {
      Callbacks.Create()
    }
  }

  const Callbacks = {
    Create: async () => {
      try {
        await request(`${config.API}/marks`, 'POST', {
          title: values
        }, {
          Authorization: token
        })
        await window.location.reload(false)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className='table-track table-track--cars'>

      <Table.CustomCell>
        <Form.InputMulti
          value={value}
          values={values}
          inputHandler={Events.inputHandler}
          pressHandler={Events.pressHandler}
          removeHandler={Events.removeHandler}
        />
      </Table.CustomCell>

      <Table.Cell
        buttonTitle='Создать'
        buttonType='default'
        buttonHandler={Events.createHandler}
      />

    </div>
  )
}
export default NewCar