import React, { useContext } from "react";

import * as Form from "../../../components/Form";
import * as Buttons from "../../../components/Buttons";

import { IDetailBlockProps } from "./types";
import * as Contexts from "../../../contexts";

const DetailBlock: React.FC<IDetailBlockProps> = ({
  data,
  deleteHandler,
  blockHandler,
}) => {
  const { token } = useContext(Contexts.AuthContext);

  return (
    <div className="page-header consumers-header">
      <div className="consumers-header-part">
        {data?.avatar !== undefined ? (
          <img
            className="order-view-image"
            alt="Logo"
            src={"" + data?.avatar + `?token=${token}`}
          />
        ) : (
          <img alt="Logo" src="http://placehold.it/140" />
        )}

        <div className="consumers-header-column">
          <Form.DetailField label="Имя:" value={data?.firstName} />

          <Form.DetailField label="Фамилия:" value={data?.lastName} />

          <Form.DetailField
            label="Дата регистрации:"
            value={
              data?.createdAt && new Date(data?.createdAt).toLocaleDateString()
            }
          />

          <Form.DetailField label="Почта:" value={data?.email} />
        </div>

        <div className="consumers-header-column">
          <Form.DetailField label="Мобильный:" value={data?.phone} />
        </div>
      </div>

      <div className="consumers-header-part">
        <div className="consumers-header-column">
          <Buttons.Default
            title="Удалить профиль"
            type="button"
            buttonType="delete"
            buttonHandler={deleteHandler}
          />

          {data?.status !== "blocked" ? (
            <Buttons.Default
              title="Заблокировать"
              type="button"
              buttonType="delete"
              buttonHandler={blockHandler}
            />
          ) : (
            <Buttons.Default
              title="Разблокировать"
              type="button"
              buttonType="green"
              buttonHandler={blockHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DetailBlock;
