import React from 'react'
import * as Contexts from '.'


const Collection: React.FC = ({ children }) => {

  return (
    <Contexts.AuthProvider>
      <Contexts.ConfigProvider>
        <Contexts.UserProvider>
            {/* <Contexts.WSContextProvider> */}
          {children}
            {/* </Contexts.WSContextProvider> */}
        </Contexts.UserProvider>
      </Contexts.ConfigProvider>
    </Contexts.AuthProvider>
  )
}

export default Collection
