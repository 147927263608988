import React, {useState, useEffect, useContext} from 'react'

import * as Table from '../../../../components/Table'
import * as Form from '../../../../components/Form'

import { ICompany } from '../../types'

import { config } from '../../../../config/config'
import * as Contexts from "../../../../contexts";
import {useHttp} from "../../../../hooks/http.hook";
import { useMesasge } from "../../../../hooks/message.hook";
import {type} from "os";


const NewCompany: React.FC = () => {

  const [isCorrect, toggleCorrect] = useState({
    title: true,
    description: true,
    phone: true
  })
  const [form, setForm] = useState<ICompany>({
    title: '',
    description: '',
    phone: ''
  })

  const message = useMesasge();

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: () => {
      Callbacks.Create()
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
         const regexPhone = /^([\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
         if (!regexPhone.test(form.phone)) {
           message('Не валидный номер телефона!')
         } else {
           const responce = await request(`${config.API}/movers`, 'POST', form, {
             Authorization: token
           })

           if (responce) {
             await window.location.reload(false)
           }
         }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (form.title.length > 0) toggleCorrect({ ...isCorrect, title: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.title])

  useEffect(() => {
    if (form.description.length > 0) toggleCorrect({ ...isCorrect, description: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.description])

  useEffect(() => {
    if (form.phone.length > 0) toggleCorrect({ ...isCorrect, phone: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.phone])


  return (
    <div className={`table-track table-track--companies table-track--companies--create`}>

      <Table.CustomCell>
        <Form.Input
          name='title'
          value={form.title}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          name='phone'
          value={form.phone}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Textarea
          name='description'
          value={form.description}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.Cell
        type='button'
        buttonTitle='Создать'
        buttonType='default'
        buttonHandler={Events.createHandler}
      />

    </div>
  )
}

export default NewCompany
