import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../../contexts'

import { useHttp } from '../../../hooks/http.hook'

import { ICardProps } from './types'
import { IRules } from '../types'

import { config } from '../../../config/config'

import { useMesasge } from "../../../hooks/message.hook";

import PersonEdit from './PersonEdit'
import Checkboxes from './Checkboxes'


const NewCard: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext)
  let { setMessages, messages } = useContext(Contexts.WSContext)

  const { loading, request } = useHttp()
  const message = useMesasge()

  const [isLogin, toggleLogin] = useState<boolean>(true)
  const [isPassword, togglePassword] = useState<boolean>(true)
  const [isEmail, toggleEmail] = useState<boolean>(true)
  const [form, setForm] = useState({
    login: '',
    password: '',
    email: '',
    type: 'Manager',
    rules: {
      orders: false,
      drivers: false,
      companies: false,
      consumers: false,
      finance: false,
      settings: false,
      statistics: false,
      tariffs: false,
      users: false,
      withdraw: false
    }
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === 'text') {
        setForm({ ...form, [e.target.name]: e.target.value })
      } else if (e.target.type === 'checkbox') {
        setForm({
          ...form, rules: {
            ...form.rules,
            [e.target.name]: e.target.checked
          }
        })
      }
    },
    createHandler: () => {
      Callbacks.Create()
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        if (form.email !== '' && form.login !== '' && form.password !== '') {
          const response = await request(`${config.API}/register`, 'POST', {...form}, {
            Authorization: token
          })

          if (!response) {
            message('В системе уже есть пользователь с подобным логином!')
          } else {
            await window.location.reload(false)
          }
        } else {
          message('Не все поля заполнены!')
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (form.login.length >= 4) toggleLogin(true)
    else toggleLogin(false)
  }, [form.login])

  useEffect(() => {
    if (form.password.length >= 4) togglePassword(true)
    else togglePassword(false)
  }, [form.password])

  useEffect(() => { console.log(isEmail)
    if (form.email.length >= 4 && form.email.search('@') !== -1) toggleEmail(true)
    else toggleEmail(false)
  }, [form.email])


  return (
    <div className='settings-card'>
      <div className='settings-card-container'>

        <PersonEdit
          _id='create'
          type='create'
          login={form.login}
          isLogin={isLogin}
          isPassword={isPassword}
          isEmail={!isEmail}
          password={form.password}
          disabledButton={loading}
          inputHandler={Events.inputHandler}
          createHandler={Events.createHandler}
        >

          <Checkboxes
            id='create'
            items={form.rules}
            inputHandler={Events.inputHandler}
          />

        </PersonEdit>

      </div>
    </div>
  )
}

export default NewCard
