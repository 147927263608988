import React from 'react'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {

  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        text={(data.orderNumber) ? '#' + data.orderNumber : ''}
      />

      <Cell
        text={new Date(data.createdAt).toLocaleDateString() + '/' + new Date(data.createdAt).toLocaleTimeString()}
      />

      {/* data.source.driver ? (
        <Cell
          type='link'
          text={data.source.title}
          linkTitle={data.source.driver.fullName}
          linkTo={`/${data.source.driver?._id}`}
        />
      ) : (
          <Cell
            text={data.source.title}
          />
        ) */}
      <Cell
        text={data.event}
      />

      <Cell
        text={`${data.sum}`}
        textColor={data.status === 'Новый' || data.status === 'Успешный' ? 'black' : 'red'}
        textWeight='bold'
      />

      <Cell
        text={data.status}
        textColor={data.status === 'Новый' || data.status === 'Успешный' ? 'black' : 'red'}
      />

    </div>
  )
}

export default Track
