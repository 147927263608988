import React, { useState, useEffect, Fragment } from 'react'
import * as Page from '../../../../components/Page'
import * as Form from '../../../../components/Form'
import { ICargoBlockProps } from './types'
import Modal from '../Modal'
import { TModalContent } from '../../types'


const CargoBlock: React.FC<ICargoBlockProps> = ({
  data
}) => {

  const [images, setImages] = useState<string[]>([])
  const [modal, setModal] = useState<TModalContent>(null)

  const Events = {
    setModalHandler: () => {
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
      setModal('slider')
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  }

  useEffect(() => {
    const arr: string[] = []
    arr.push(data?.cargoCompartment.photos.one!)
    arr.push(data?.cargoCompartment.photos.two!)
    for (let i = 0; i < data?.cargoCompartment.photos.other.length!; i++) {
      arr.push(data?.cargoCompartment.photos.other[i]!)
    }
    setImages(arr)
  }, [data?.cargoCompartment.photos])

  return (
    <Fragment>

      <Modal
        type={modal}
        data={data}
        images={images}
        clearModal={Events.clearModal}
      />

      <div className='docs-block'>
        <div className='docs-column'>

          <Page.Images
            images={images}
            clickHandler={Events.setModalHandler}
          />

          <h4 className='docs-title'>
            Грузовой отсек
          </h4>

          <Form.DetailField
            label='ДхШхВ'
            value={`${data?.cargoCompartment.length}x${data?.cargoCompartment.width}x${data?.cargoCompartment.height}`}
          />

          <Form.DetailField
            label='Обьем (м3):'
            value={`${data?.cargoCompartment.volume!}`}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default CargoBlock