import React, { useContext } from 'react'

import { IImagesProps } from './types'
import { AuthContext } from '../../contexts'


const Images: React.FC<IImagesProps> = ({
  images = [],
  clickHandler
}) => {

  const { token } = useContext(AuthContext)

  if (!images.length) {
    return (
      <div className='page-images-column'>
        error
      </div>
    )
  }

  return (
    <div
      className='page-images'
      onClick={clickHandler}
    >

      <div className='page-images-column'>
        <img
          alt='doc'
          className='page-images--first'
          src={images[0] + `/?token=${token ? token : ''}`}
        />
      </div>

      <div className='page-images-column'>
        {images.map((image, index) => {
          if (!index) return null
          else return (
            <img
              key={index}
              alt='doc'
              src={image + `/?token=${token ? token : ''}`}
            />
          )
        })}
      </div>

    </div>
  )
}

export default Images