import React, { useState } from 'react'

import { IToggleTextareaProps } from './types'

import Edit from '../../assets/edit.png'
import Check from '../../assets/check.png'


const InputToggle: React.FC<IToggleTextareaProps> = ({
  type,
  name,
  value,
  changeHandler = () => { }
}) => {

  const [isActive, toggleActive] = useState<boolean>(false)

  const Events = {
    activeHandler: () => {
      toggleActive(!isActive)
    }
  }

  return (
    <div className='input-toggle'>

      <textarea
        name={name}
        className={`
          textarea-custom 
          ${isActive && 'textarea-custom--change'}
          ${type === 'table' ? 'textarea-custom--table' : ''}
        `}
        disabled={!isActive}
        value={value}
        onChange={changeHandler}
      />

      <img
        className='btn-table-edit'
        alt={!isActive ? 'Edit' : 'Submit'}
        src={!isActive ? Edit : Check}
        onClick={Events.activeHandler}
      />

    </div>
  )
}


export default InputToggle