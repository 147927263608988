import React from 'react'

import TableWrapper from '../../../../components/Table/Table'

import Track from './Track'

import { ITableProps } from './types'

import { titles } from './data'


const Table: React.FC<ITableProps> = ({
    items,
    sortHandler
}) => {
// console.log(items);
    return (
        <TableWrapper
            header={{
                type: 'drivers',
                cells: titles
            }}
            padding='bottom'
            sortHandler={sortHandler}
        >

            {items.map(item => (
                <Track
                    key={item._id}
                    type='drivers'
                    data={item}
                />
            ))}

        </TableWrapper>
    )
}



export default Table
