import React from 'react'
import { HashRouter } from 'react-router-dom'
//materialize-css
import 'materialize-css'

import * as Contexts from './contexts'
import Computed from './components/Blocks/Computed'



function App() {

  return (
    <HashRouter>
      <Contexts.Collection>
        <Computed />
      </Contexts.Collection>
    </HashRouter>
  )
}

export default App
