import React, { useContext, useState, ChangeEvent, Fragment } from "react";

import * as Contexts from "../../../../contexts";
import * as Table from "../../../../components/Table";
import * as Form from "../../../../components/Form";

import { useHttp } from "../../../../hooks/http.hook";
import { config } from "../../../../config/config";
import { TTariffForm } from "../../types";
import { useMesasge } from "../../../../hooks/message.hook";

const NewTariff: React.FC = () => {
  const { services } = useContext(Contexts.ConfigContext)!;
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();
  const message = useMesasge();
  let isValid = true;
  const [form, setForm] = useState<TTariffForm>({
    image: "",
    title: "",
    hidden: false,
    pricePerHour: 1,
    minOrdersHours: 1,
    widthRange: { min: 1, max: 2 },
    weightRange: { min: 1, max: 2 },
    lengthRange: { min: 1, max: 2 },
    heightRange: { min: 1, max: 2 },
    capacityRange: { min: 1, max: 2 },
  });

  const Events = {
    changeHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const target = e.target;
      const name = e.target.name.split("-")[0];
      const subname = e.target.name.split("-")[1];
      /* if (target.name === 'title' && target.value !== 'Название тарифа') {
        console.log(target.name, target.value);
        target.value = '';
      } */
      if (subname) {
        if (target.name.split("-")[1] === "min") {
          // @ts-ignore
          if (+target.value >= form[name]["max"]) {
            // @ts-ignore
            target.value = form[name]["min"];
          }
        } else {
          // @ts-ignore
          if (+target.value < form[name]["min"]) {
            // @ts-ignore
            target.value = form[name]["max"];
          }
        }
        // @ts-ignore
        if (+target.value > 0)
          setForm({
            ...form!,
            [name]: {
              //@ts-ignore
              ...form[name],
              [subname]: +target.value,
            },
          });
      } else {
        if (+target.value < 1) {
          // @ts-ignore
          target.value = 1;
        }
        if (target.type === "file") {
          //@ts-ignore
          setForm({ ...form, image: target.files[0] });
        } else {
          setForm({ ...form!, [target.name]: target.value });
        }
      }
    },
    createHandler: () => {
      Callbacks.Create();
    },
  };

  const Callbacks = {
    Create: async () => {
      try {
        let image: string = "";

        if (form.image === "") {
          message("Image is required!");
          isValid = false;
        }
        if (form?.image && isValid) {
          const data = new FormData();
          data.append("file", form.image);
          const response_image: any = await request(
            `${services.image_server_api}/api/images/upload/public`,
            "POST",
            data,
            {
              Authorization: token,
            }
          );
          image = response_image.endpoint;
        }

        const sendData = {
          ...(form?.image ? { ...form, image } : form),
        };

        let response: any;
        if (isValid) {
          response = await request(
            `${config.API}/tariffs`,
            "POST",
            { ...sendData },
            {
              Authorization: token,
            }
          );
        }
        if (response) await window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
  };

  return (
    <div className={`table-track table-track--tariffs`}>
      <Table.MainCell
        isCreate
        title={"Название тарифа:"}
        name="title"
        value={form.title}
        events={Events}
      />

      <Table.CustomCell>
        <div>
          <img alt="Logo" src="http://placehold.it/140" />
          <Form.Input
            type="file"
            name="image"
            inputHandler={Events.changeHandler}
          />
        </div>
      </Table.CustomCell>

      <Table.CustomCell>
        <Fragment>
          <Form.Input
            isCreate
            type="number"
            name="lengthRange-min"
            label="Длина от (м):"
            value={form.lengthRange.min}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="widthRange-min"
            label="Ширина от (м):"
            value={form.widthRange.min}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="heightRange-min"
            label="Высота от (м):"
            value={form.heightRange.min}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="weightRange-min"
            label="Грузоподъемность от (т):"
            value={form.weightRange.min}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="capacityRange-min"
            label="Объем от (т):"
            value={form.capacityRange.min}
            inputHandler={Events.changeHandler}
          />
        </Fragment>
      </Table.CustomCell>

      <Table.CustomCell>
        <Fragment>
          <Form.Input
            isCreate
            type="number"
            name="lengthRange-max"
            label="Длина до (м):"
            value={form.lengthRange.max}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="widthRange-max"
            label="Ширина до (м):"
            value={form.widthRange.max}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="heightRange-max"
            label="Высота до (м):"
            value={form.heightRange.max}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="weightRange-max"
            label="Грузоподъемность до (т):"
            value={form.weightRange.max}
            inputHandler={Events.changeHandler}
          />

          <Form.Input
            isCreate
            type="number"
            name="capacityRange-max"
            label="Объем до (т):"
            value={form.capacityRange.max}
            inputHandler={Events.changeHandler}
          />
        </Fragment>
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          isCreate
          type="number"
          name="minOrdersHours"
          value={form.minOrdersHours}
          inputHandler={Events.changeHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          isCreate
          type="number"
          name="pricePerHour"
          value={form.pricePerHour}
          inputHandler={Events.changeHandler}
        />
      </Table.CustomCell>

      <Table.Cell text={"0"} textWeight="bold" direction="column" />
    </div>
  );
};

export default NewTariff;
