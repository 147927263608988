import React, { useContext } from 'react'

import * as Contexts from '../../../../contexts'

import { useHttp } from '../../../../hooks/http.hook'

import { config } from '../../../../config/config'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {

  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const Events = {
    deleteHandler: () => {
      const response = window.confirm('Delete?')
      if (response) Callbacks.Delete()
    }
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/marks/${data._id}`, 'DELETE', null, {
          Authorization: token
        })
        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        textWeight='bold'
        text={data.title}
      />

      <Cell
        buttonTitle='Удалить'
        buttonType='delete'
        buttonHandler={Events.deleteHandler}
      />

    </div>
  )
}

export default Track