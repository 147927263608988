import React, { useState, useContext, useEffect } from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks/http.hook";

import { config } from "../../config/config";

import { IPagination, IFilter } from "../../types";
import { IWithdraw } from "./types";
import { useHistory } from "react-router-dom";
import { WithdrawDummy } from "./data";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [withdraw, setWithdraw] = useState<IWithdraw[]>([]);
  const [xslsData, setXSLSData] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "createdAt",
    sortOrder: "asc",
    fullName: "",
    phone: "",
    dateUse: false,
    dateFrom: "",
    dateTo: "",
  });
  const history = useHistory();

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1];
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.FetchWithdraw(1);
    },
    timeHandler: () => {
      setFilter({ ...filter, dateUse: !filter.dateUse });
    },
    searchHandler: () => {
      Callbacks.FetchWithdraw(1);
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
  };

  const Callbacks = {
    FetchWithdraw: async (page?: number) => {
      try {
        const response: any = await request(
          `${config.ordersAPI}/finances/withdrawal?page=${
            page ? page : pagination.page
          }&perPage=${pagination.perPage}&sortOrder=${
            filter.sortOrder
          }&sortBy=${filter.sortBy}&fullName=${filter.fullName}&phone=${
            filter.phone
          }&createdAt[]=${filter.dateUse ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateUse ? filter.dateTo : ""
          }`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        const responseSXLS: any = await request(
          `${config.ordersAPI}/finances/withdrawal?page=${1}&perPage=${
            response.data?.pagination?.total
          }&createdAt[]=${filter.dateUse ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateTo
          }&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];

        if (!response.data) {
          history.push("/perm_error");
        }

        responseSXLS.data.forEach((resp: any) => {
          Arr.push({
            user: resp.user?.firstName + " " + resp.user?.lastName,
            dateTime:
              new Date(resp.createdAt).toLocaleDateString() +
              " " +
              new Date(resp.createdAt).toLocaleTimeString(),
            status: resp.status,
            balance: resp.balance,
            sum: resp.sum,
            cardNumber: resp.cardNumber,
            action: resp.action,
          });
        });

        setXSLSData(Arr);
        setWithdraw(response.data);
        if (response.pagination)
          setPagination({
            ...pagination,
            totalCount: response.pagination.total,
            page: response.pagination.page,
          });
        else setPagination({ ...pagination, totalCount: 0 });

        // setWithdraw(WithdrawDummy)
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchWithdraw();
  }, [
    filter.sortBy,
    filter.sortOrder,
    pagination.page,
    filter.dateUse /*filter.dateFrom, filter.dateTo*/,
  ]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <div className="page-wrapper">
      <Page.Filter
        agePicker
        registerDateSelect
        phoneInput
        fullNameInput
        form={filter}
        events={Events}
      >
        <CSV.ExcelUpload csvData={xslsData} fileName={"withdraw"} />
      </Page.Filter>

      <Components.Table items={withdraw} />

      <Page.Pagination
        linkTo="/withdraw"
        pagination={pagination}
        paginateHandler={Events.paginateHandler}
      />
    </div>
  );
};

export default IndexPage;
