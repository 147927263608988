import React, {useContext} from 'react'
import * as Contexts from '../../contexts'
import { NavLink } from 'react-router-dom'


const SideBar: React.FC = () => {

    const { type, rules } = useContext(Contexts.UserContext)

    const linkHandler = (e: any) => {
        e.target.classList.add('active')

        if (e.target.classList.contains('active')) {
            // window.location.reload(false)
        }
    }

    return (
        <aside className='side-bar'>
            <img
                src={require('../../assets/mini-logo.png')}
                className='side-bar-img'
                alt='Logo'
            />

            <nav className='side-bar-nav'>
                { type === 'Admin' || rules?.orders === true ?
                <NavLink
                    className='side-bar-link'
                    to='/orders'
                    onClick={linkHandler}
                >
                    Заказы
                </NavLink> : null
                }
                { type === 'Admin' || rules?.consumers === true ?
                <NavLink
                    className='side-bar-link'
                    to='/consumers'
                    onClick={linkHandler}
                >
                    Клиенты
                </NavLink> : null
                }

                { type === 'Admin' || rules?.drivers === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/drivers'
                        onClick={linkHandler}
                    >
                        Водители
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.finance === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/finance'
                        onClick={linkHandler}
                    >
                        Финансы
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.tariffs === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/tariffs'
                        onClick={linkHandler}
                    >
                        Тарифы
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.settings === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/settings'
                        onClick={linkHandler}
                    >
                        Настройки
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.users === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/users'
                        onClick={linkHandler}
                    >
                        Компании
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.companies === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/companies'
                        onClick={linkHandler}
                    >
                        Грузокомпании
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.withdraw === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/withdraw'
                        onClick={linkHandler}
                    >
                        Вывод средств
                    </NavLink> : null
                }

                {type === 'Admin' || rules?.statistics === true ?
                    <NavLink
                        className='side-bar-link'
                        to='/statistics'
                        onClick={linkHandler}
                    >
                        Статистика
                    </NavLink> : null
                }
            </nav>
        </aside>
    )
}



export default SideBar
