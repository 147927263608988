import React from 'react'

import { IInputProps } from './types'


const Input: React.FC<IInputProps> = ({
  type = 'text',
  name,
  disabled,
  margin = '',
  isCreate = false,
  value,
  minDate,
  maxDate,
  placeholder = '',
  label,
  style,
  inputHandler = () => { },
  pressHandler = () => { }
}) => {

  if (type === 'file') {
    return (
      <input
        className='input-file'
        name='image'
        type={type}
        onChange={inputHandler}
      />
    )
  }

  return (
    <div className={isCreate ? `
    input-create
    ` : `
    input-custom-wrapper 
    input-custom-wrapper--margin-${margin}

    `}>
      <h4 className='input-custom-label'>
        {label}
      </h4>

      <input
        className={isCreate ? 'input-show--number' : `
        input-custom input-custom--${name} 
        input-custom--${type}
        `}
        type={type}
        name={name}
        placeholder={placeholder}
        min={minDate}
        max={maxDate}
        style={style}
        value={value}
        onChange={inputHandler}
        onKeyPress={pressHandler}
        disabled={disabled}
      />
    </div>
  )
}

export default Input
