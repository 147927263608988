import React, { createContext, useState, useCallback, useEffect } from 'react'

import { IAuthContext, EVars } from './types'

export const AuthContext = createContext<IAuthContext>({
    token: null,
    userID: null,
    isAuthenticated: false,
    rules: {},
    type: 'Admin',
    notif: false,
    ready: false,
    login: () => { },
    logout: () => { }
})

export default AuthContext

export const AuthProvider: React.FC = ({ children }) => {
    const [token, setToken] = useState<string | null>(null)
    const [userID, setUserID] = useState<string | null>(null)
    const [ready, setReady] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [rules, setRules] = useState<any>({})
    const [type, setType] = useState<string>('Admin')
    const [notif, setNotif] = useState<boolean>(true)

    const login = useCallback((token: string, userID: string, rules: any, type: string, notif: boolean) => {
        setToken(token)
        setUserID(userID)
        setRules(rules)
        setType(type)
        setNotif(notif);

        // (!notif) ? localStorage.setItem('notif', String(notif)) : localStorage.removeItem('notif')

        localStorage.setItem(EVars.USER_DATA,
            JSON.stringify({ token, userID, rules, type }))

        setIsAuthenticated(true)
    }, [])

    const logout = useCallback(() => {
        setToken(null)
        setUserID(null)
        setRules({})
        setType('Admin')

        localStorage.removeItem('notif')
        localStorage.removeItem(EVars.USER_DATA)

        setIsAuthenticated(false)
    }, [])

    useEffect(() => {
        const fetched: any = localStorage.getItem(EVars.USER_DATA)

        const data = JSON.parse(fetched)

        if (data && data.token) {
            login(data.token, data.userID, data.rules, data.type, data.notif)
        }

        setReady(true)
    }, [login])


    return (
        <AuthContext.Provider value={{
            token, userID, isAuthenticated, rules, type, notif, ready, login, logout
        }}>
            {children}
        </AuthContext.Provider>
    )
}
