import React, { Fragment } from 'react'

import * as Form from '../../../../components/Form'

import { ICostomCellProps } from './types'


const CustomCell: React.FC<ICostomCellProps> = ({
  type = 'field',
  isCorrect,
  dimensionsType,
  inputType,
  name,
  prefix,
  suffix,
  form,
  value = '' || 0,
  changeHandler = () => { }
}) => {

  return (
    <div className='table-cell table-cell--column'>

      {type === 'dimensions' ? (
        dimensionsType === 'from' ? (
          <Fragment>
            <Form.InputToggle
              name='lengthRange-min'
              label='Длина от (м):'
              value={`${form?.lengthRange.min}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='widthRange-min'
              label='Ширина от (м):'
              value={`${form?.widthRange.min}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='heightRange-min'
              label='Высота от (м):'
              value={`${form?.heightRange.min}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='weightRange-min'
              label='Грузоподъемность от (т):'
              value={`${form?.weightRange.min}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='capacityRange-min'
              label='Объем от (м3):'
              value={`${form?.capacityRange.min}`}
              changeHandler={changeHandler}
            />
          </Fragment>
        ) : dimensionsType === 'to' ? (
          <Fragment>
            <Form.InputToggle
              name='lengthRange-max'
              label='Длина до (м):'
              value={`${form?.lengthRange.max}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='widthRange-max'
              label='Ширина до (м):'
              value={`${form?.widthRange.max}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='heightRange-max'
              label='Высота до (м):'
              value={`${form?.heightRange.max}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='weightRange-max'
              label='Грузоподъемность до (т):'
              value={`${form?.weightRange.max}`}
              changeHandler={changeHandler}
            />

            <Form.InputToggle
              name='capacityRange-max'
              label='Объем до (м3):'
              value={`${form?.capacityRange.max}`}
              changeHandler={changeHandler}
            />
          </Fragment>
        ) : null
      ) : null}

      {type === 'field' ? (
        <Form.InputToggle
          name={(name as string)}
          value={value}
          changeHandler={changeHandler}
        />
      ) : null}

    </div>
  )
}

export default CustomCell