import React from 'react'

import * as Buttons from '../../../components/Buttons'

import { IHeaderProps } from './types'


const Header: React.FC<IHeaderProps> = ({
  addHandler
}) => {

  return (
    <div className='filter-bar filter-bar--felx-start filter-bar--right filter-bar--bottom'>

      <div></div>

      <Buttons.Default
        type='button'
        title='Новый'
        buttonType='plus'
        buttonHandler={addHandler}
      />

    </div>
  )
}


export default Header