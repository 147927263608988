import React, { useContext, useState, Fragment } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { useHttp } from '../../../../hooks/http.hook'

import { config } from '../../../../config/config'

import { IDeactivateModalProps } from './types'
import { Link } from 'react-router-dom'



const DeactivateModal: React.FC<IDeactivateModalProps> = ({
  data,
  clearModal
}) => {

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [reason, setReason] = useState<string>('')

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setReason(e.target.value)
    },
    cancelHandler: () => {
      clearModal()
    },
    deactivateHandler: () => {
      Callbacks.Deactivate()
    }
  }

  const Callbacks = {
    Deactivate: async () => {
      try {
        await request(`${config.API}/cars/${data?._id}/status/deactivated`, 'POST', null, {
          Authorization: token
        })
        await window.location.reload(true)
        clearModal()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <Fragment>

      <h4 className='page-modal-title'>
        Деактивировать автомобиль
      </h4>

      <p className='page-modal-desc'>
        Вы уверены что хотите деактивировать автомобиль?
      </p>

      <p className='page-modal-desc'>
        Заказы на этот автомобиль
        &nbsp;

          <Link to='/'>
          {data?.license}
        </Link>

          &nbsp;
        перенаправятся другим водителям.
      </p>



      <div className='page-modal-btns'>
        <Buttons.Large
          type='cancel'
          title='Отмена'
          buttonHandler={Events.cancelHandler}
        />

        <Buttons.Large
          type='ok'
          title='Деактивировать'
          buttonHandler={Events.deactivateHandler}
        />
      </div>

    </Fragment>
  )
}
export default DeactivateModal