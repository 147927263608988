import React, { useContext, useState, useEffect, Fragment } from 'react'
import {useHistory, useParams} from 'react-router-dom'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { config } from '../../config/config'

import { IFilter } from '../../types'
import { IDriver, IOperate, TModalContent } from './types'

import { OpirateDummy } from './data'



const DetailPage: React.FC = () => {

  const { id } = useParams()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const history = useHistory();

  const [driver, setDriver] = useState<IDriver | null>(null)
  const [operations, setOperations] = useState<IOperate[]>([])
  const [modal, setModal] = useState<TModalContent>(null)
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'date',
    sortOrder: 'asc',
    orderNumber: '',
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split('-')[0]
      const sortOrder = e.target.value.split('-')[1]
      setFilter({ ...filter, sortBy, sortOrder })
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.FetchOperations()
    },
    searchHandler: () => {
      Callbacks.FetchOperations()
    },
    fineHandler: () => {
      setModal('fine')
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    deleteHandler: () => {
      const response = window.confirm('Delete?')
      if (response) Callbacks.Delete()
    },
    unblockHandler: () => {
      const response = window.confirm('Unblock?');
      if (response) Callbacks.UnBlock()
    },
    blockHandler: () => {
      const response = window.confirm('Block?')
      if (response) Callbacks.Block()
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  }

  const Callbacks = {
    FetchDriver: async () => {
      try {
        const response: any = await request(
          `${config.API}/drivers/${id}`, 'GET', null, {
          Authorization: token
        })

        setDriver(response.data)
      } catch (e) {
        console.log(e)
      }
    },
    FetchOperations: async () => {
      try {
         const response: any = await request(
           `${config.ordersAPI}/finances/${id}/moneyTransfer`, 'GET', null, {
           Authorization: token
         })

        response.data.forEach((item: any) => {
          if (item.action === 'plus') {
            item.action = 'Плюс';
          } else if (item.action === 'minus') {
            item.action = 'Минус';
          }

          if (item.event === 'orderPayment') {
            item.event = 'Плата за заказ';
          } else if (item.event === 'withdrawal') {
            item.event = 'Вывод средств';
          } else if (item.event === 'deposit') {
            item.event = 'Пополнение баланса';
          }

          if (item.status === 'successful') {
             item.status = 'Удачно';
          } else if (item.status === 'new') {
             item.status = 'Новый';
          } else if (item.status === 'paymentError') {
             item.status = 'Ошибка оплаты';
          } else if (item.status === 'paymentProcessing') {
             item.status = 'Обработка платежа';
          } else if (item.status === 'paymentRefunded') {
             item.status = 'Оплата проверена';
          }
        });

        setOperations(response.data)
      } catch (e) {
        console.log(e)
      }
    },
    UnBlock: async () => {
      try {
        await request(
            `${config.API}/drivers/unblock/${id}`, 'POST', null, {
              Authorization: token
            })
        await Callbacks.FetchDriver()
      } catch (e) {
        console.log(e)
      }
    },
    Block: async () => {
      try {
        await request(
            `${config.API}/drivers/ban/${id}`, 'POST', null, {
              Authorization: token
            })
       await Callbacks.FetchDriver()
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(
            `${config.API}/drivers/${id}`, 'DELETE', null, {
              Authorization: token
            })
        history.push('/drivers');
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchDriver()
    Callbacks.FetchOperations()
  }, [])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }


  return (
    <Fragment>

      <Components.Modal
        type={modal}
        data={driver}
        clearModal={Events.clearModal}
      />

      <Page.BackLink
        title='Вернуться к профилю водителя'
        linkTo={`/drivers/${id}`}
      />

      <div className='page-wrapper'>
        <Components.DetailBlock
          data={driver}
          fineHandler={Events.fineHandler}
          blockHandler={driver?.status !== 'blocked' ? Events.blockHandler : Events.unblockHandler}
          deleteHandler={Events.deleteHandler}
        />

        <Page.Filter
          dateSelect
          statusSelect
          actionSelect
          form={filter}
          events={Events}
        >

          <Form.DetailField
            type='filter'
            label='Операций'
            value={`${operations.length}`}
          />

        </Page.Filter>

        <Components.HistoryTable
          items={operations}
        />

        <Page.Footer
          data={driver?.ordersSum}
        />

      </div>
    </Fragment>
  )
}


export default DetailPage
