import React from 'react'

import { IDefault } from './types'
import { Link } from 'react-router-dom'


const Default: React.FC<IDefault> = ({
  type,
  title,
  style,
  linkTo = '/',
  buttonType,
  buttonHandler = () => { }
}) => {

  if (type === 'link') {
    return (
      <Link
        className='btn'
        to={linkTo}
        style={style}
      >

        <img
          className='btn-img'
          src={require('../../assets/plus.png')}
          alt='Add'
        />

        <span>{title}</span>
      </Link>
    )
  }

  return (
    <button
      className={`btn btn--${buttonType}`}
      onClick={buttonHandler}
    >

      {buttonType === 'delete' ? (
        <img
          className='btn-img'
          src={require('../../assets/cross.png')}
          alt='Add'
        />
      ) : null}

      {buttonType === 'fine' ? (
        <img
          className='btn-img'
          src={require('../../assets/dash.png')}
          alt='Add'
        />
      ) : null}

      {buttonType === 'plus' ? (
        <img
          className='btn-img'
          src={require('../../assets/plus.png')}
          alt='Add'
        />
      ) : null}

      <span>{title}</span>
    </button>
  )
}


export default Default