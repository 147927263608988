import React from "react";

import { ISelectProps } from "./types";

import {
  actionOptions,
  dateOptions,
  registerOptions,
  orderDateTimeOptions,
  fullNameOptions,
  carsOptions,
  statusOptions,
  ordersStatusOptions,
  tariffOptions,
  sumOptions,
  balanceOptions,
} from "./data";

const Select: React.FC<ISelectProps> = ({
  name,
  value,
  options,
  label,
  actionSelect,
  dateSelect,
  registerDateSelect,
  dateTimeSelect,
  fullNameSelect,
  carsSelect,
  statusSelect,
  tariffSelect,
  balanceSelect,
  sumSelect,
  ordersStatusSelect,
  selectHandler = () => {},
}) => {
  return (
    <div>
      <h4 className="input-custom-label">{label}</h4>

      <select
        className={`select-custom select-custom--${name}`}
        name={name}
        value={value}
        onChange={selectHandler}
      >
        {options
          ? options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {actionSelect
          ? actionOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {dateSelect
          ? dateOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {registerDateSelect
          ? registerOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {dateTimeSelect
          ? orderDateTimeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {sumSelect
          ? sumOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {balanceSelect
          ? balanceOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}

        {fullNameSelect
          ? fullNameOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {carsSelect
          ? carsOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {statusSelect
          ? statusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {ordersStatusSelect
          ? ordersStatusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
        {tariffSelect
          ? tariffOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))
          : null}
      </select>
    </div>
  );
};
export default Select;
