import React from 'react'

import { ILargeProps } from './types'


const Small: React.FC<ILargeProps> = ({
 type,
 title,
 buttonHandler = () => { }
}) => {

    return (
        <button
            className={`table-btn table-btn--default`}
            onClick={buttonHandler}
        >

            <span>{title}</span>

        </button>
    )
}


export default Small
