import React from 'react'

import * as Form from '../../components/Form'
import * as Buttons from '../../components/Buttons'

import { IMainCellProps } from './types'
import { Link } from 'react-router-dom'


const MainCell: React.FC<IMainCellProps> = ({
  title,
  name,
  value,
  isCreate = false,
  isCorrect = true,
  link,
  options,
  events
}) => {

  return (
    <div className='table-cell table-cell--column'>

      {name && !options && events.changeHandler ? (
          <div>
         {title}
        <Form.InputToggle
          type='text'
          name={name}
          value={value}
          isCorrect={isCorrect}
          changeHandler={events.changeHandler}
        />
          </div>
      ) : null}

      {name && options && isCreate && events.changeHandler ? (
        <Form.Select
          name={name}
          options={options}
          value={value}
          selectHandler={events.changeHandler}
        />
      ) : null}

      {name && !isCreate && options && events.changeHandler ? (
        <Form.SelectToggle
          name={name}
          options={options}
          value={value}
          changeHandler={events.changeHandler}
        />
      ) : null}

      {link ? (
        <Link
          className='link-default'
          to={link.to}
        >
          {link.title}
        </Link>
      ) : null}

      {events.deleteHandler ? (
        <Buttons.Table
          type='delete'
          title='Удалить'
          margin
          buttonHandler={events.deleteHandler}
        />
      ) : null}

      {events.saveHandler ? (
        <Buttons.Table
          type='save'
          title='Сохранить'
          buttonHandler={events.saveHandler}
        />
      ) : null}

      {events.createHandler ? (
        <Buttons.Table
          margin
          type='save'
          title='Создать'
          buttonHandler={events.createHandler}
        />
      ) : null}

    </div>
  )
}
export default MainCell
