import React, { useContext } from "react";

import * as Form from "../../../components/Form";
import * as Buttons from "../../../components/Buttons";

import { IDetailBlockProps } from "./types";
import * as Contexts from "../../../contexts";

const DetailBlock: React.FC<IDetailBlockProps> = ({
  data,
  typesOfPayment,
  typeOfPayment,
  // deleteHandler,
  // blockHandler
  changeHandler,
  cancelHandler,
  saveHandler,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  console.log(data);

  return (
    <div>
      <div className="page-header orders-header">
        <div className="orders-header-part">
          {data[0]?.client?.avatar !== undefined ? (
            <img
              className="order-view-image"
              alt="Logo"
              src={"" + data[0]?.client?.avatar + `?token=${token}`}
            />
          ) : (
            <img alt="Logo" src="http://placehold.it/140" />
          )}

          <div className="orders-header-column">
            <Form.DetailField
              type="link"
              label="Клиент"
              link={`/consumers/${data[0]?.client?._id}`}
              value={data[0]?.client?.firstName}
            />

            <Form.DetailField
              type="unlink"
              label=""
              value={data[0]?.client?.phone}
            />
          </div>
          <div className="orders-header-column">
            <Form.DetailField label="Номер заказа:" value={data[0]?.index} />
            <Form.DetailField
              label="Дата/Время:"
              value={
                data[0]?.orderTime &&
                new Date(data[0]?.orderTime).toLocaleDateString()
              }
            />
            <Form.DetailField label="Статус заказа:" value={data[0]?.status} />
            <Form.DetailField
              label="Тип оплаты:"
              value={data[0]?.typeOfPayment}
            />
            {data[0]?.status === "Выполнен" ? (
              <Form.DetailField
                label="Сумма оплаты:"
                value={data[0]?.sum + " грн"}
              />
            ) : null}
            <Form.DetailField
              label="Оплачен:"
              value={
                data[0]?.paymentApproved === true ? "Оплачен" : "Не оплачен"
              }
            />
            <div className="detail-field">Направление:</div>
            <Form.DetailField
              type="distance"
              label="Откуда:"
              value={data[0]?.distance[0]}
            />
            <Form.DetailField
              type="distance"
              label="Куда:"
              value={data[0]?.distance[1]}
            />
          </div>
        </div>

        {data[0]?.status === "Поиск водителя" && (
          <div className="orders-header-part">
            <div className="orders-header-column">
              <Buttons.Default
                title="Отменить заказ"
                type="button"
                buttonType="delete"
                buttonHandler={cancelHandler}
              />
              {typesOfPayment[0] ? (
                <Form.Dropdown
                  items={typesOfPayment}
                  item={typeOfPayment}
                  changeHandler={changeHandler}
                />
              ) : (
                "Нет типов оплаты!"
              )}
              <Buttons.Default
                type="button"
                title="Сохранить"
                buttonType="green"
                buttonHandler={saveHandler}
              />
            </div>
          </div>
        )}
      </div>

      <div className="page-header orders-header">
        <div className="orders-header-part">
          {data[0]?.tariff?.image !== undefined ? (
            <img
              className="tariffs-view-image"
              alt="Logo"
              src={"" + data[0]?.tariff?.image + `?token=${token}`}
            />
          ) : (
            <img alt="Logo" src="http://placehold.it/140" />
          )}
          <div className="orders-header-column">
            <Form.DetailField label="Тип авто:" value={data?.car} />

            <Form.DetailField label="Тариф:" value={data[0]?.tariff?.title} />
          </div>
          <div className="orders-header-column">
            <Form.DetailField label="Комментарий:" value={data[0]?.comment} />
          </div>
        </div>
      </div>
      <div className="page-header orders-header">
        <div className="orders-header-part">
          {/*<img
                      alt='Logo'
                      src="http://placehold.it/140"
                  /> */}
          {data[0]?.driver?.avatar !== undefined ? (
            <img
              className="order-view-image"
              alt="Logo"
              src={"" + data[0]?.driver?.avatar + `?token=${token}`}
            />
          ) : (
            ""
          )}

          <div className="orders-header-column">
            {data[0]?.driver ? (
              <>
                <Form.DetailField
                  type="link"
                  label="Водитель:"
                  value={data[0]?.driver?.firstName}
                  link={`/drivers/${data[0]?.driver?._id}`}
                />

                <Form.DetailField
                  // label='Фамилия:'
                  type="unlink"
                  value={data[0]?.driver?.phone}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailBlock;
