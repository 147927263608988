import React from 'react'
import { ICarFieldProps } from './types'
import { Link } from 'react-router-dom'


const CarField: React.FC<ICarFieldProps> = ({
  driverID,
  car
}) => {

  return (
    <div className='drivers-car-field'>
      <div className='drivers-car-data'>

        <p className='drivers-car-title'>
          {car.mark.title}
        </p>

      </div>

        { (car.status !== 'active') ? <img className='drivers-document-img' src={require('../../../../images/file1.svg')} alt=""/> :
        <img className='drivers-document-img' src={require('../../../../images/file.svg')} alt=""/> }

      <Link
        className='drivers-car-link'
        to={`/drivers/docs/${car._id}`}
      >
        Детали
      </Link>
    </div>
  )
}

export default CarField
