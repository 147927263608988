import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import * as Contexts from '../../contexts'

import { useHttp } from '../../hooks/http.hook'
import { useMesasge } from '../../hooks/message.hook'
import { config } from '../../config/config'

import { IForm } from './types'


const AuthPage: React.FC = () => {

    const { login } = useContext(Contexts.AuthContext)

    const { request } = useHttp()
    const message = useMesasge()

    const [form, setForm] = useState<IForm>({
        login: '',
        password: ''
    })


    const Events = {
        changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
            setForm({ ...form, [e.target.name]: e.target.value })
        },
        pressHanlder: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') Callbacks.Login()
        },
        loginHandler: () => {
            Callbacks.Login()
        }
    }

    const Callbacks = {
        Login: async () => {
            try {
                const data: any = await request(`${config.API}/login`, 'POST', { ...form })
                if (data && data.token) {
                    login(data.token, data.user._id, data.user.rules, data.user.type, data.user.notif)
                    setForm({ ...form, login: '', password: '' })
                }
            } catch (e) {
                console.log(e)
                message(e)
            }
        }
    }


    return (
        <div className='auth-wrapper' style={{ backgroundImage: `url(${require('../../assets/auth-bg.png')})` }}>
            <div className='auth-container'>
                <img
                    className='auth-logo img'
                    alt='Logo'
                    src={require('../../assets/logo.png')}
                />

                <div className='auth-form'>
                    <input
                        className='auth-input'
                        type='text'
                        name='login'
                        value={form.login}
                        placeholder='login'
                        onChange={Events.changeHandler}
                        onKeyPress={Events.pressHanlder}
                    />

                    <input
                        className='auth-input'
                        type='password'
                        name='password'
                        value={form.password}
                        placeholder='password'
                        onChange={Events.changeHandler}
                        onKeyPress={Events.pressHanlder}
                    />

                    <button
                        className='auth-login'
                        onClick={Events.loginHandler}
                    >
                        Login
                    </button>
                </div>

                <Link
                    className='auth-forgot'
                    to='/restore'
                >
                    Forgot password?
                </Link>
            </div>
        </div>
    )
}

export default AuthPage
