import React, {useContext, useEffect, useState} from 'react'
import { useHttp } from '../../hooks/http.hook'
//contexts
import AuthContext from '../../contexts/AuthContext'
import {config} from "../../config/config";



const Header: React.FC = () => {

    const { token, logout } = useContext(AuthContext)
    const { request } = useHttp()
    const [login, setLogin] = useState<any>();


    const profileHandler = async () => {
        const response: any = await request(
            `${config.API}/profile`, 'GET', null, {
                Authorization: token
            })
        if (response) {
            setLogin(response.user.login)
        }
    }
    const logoutHandler = () => {
        logout()
    }

    useEffect(() => {
        profileHandler();
    }, [])

    return (
        <header className='header'>
            <div></div>

            <h2>
                <span className='header-hello'>Привет,</span>
                <span className='header-name'>{login}</span>
            </h2>

            <button
                className='btn-logout'
                onClick={logoutHandler}
            >
                Выход
            </button>
        </header>
    )
}



export default Header
