import React, { useContext, useState, useEffect, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as Contexts from '../../contexts'
import * as Table from '../../components/Table'
import * as Page from '../../components/Page'
import * as Form from '../../components/Form'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { config } from '../../config/config'

import {IFilter, IPagination} from '../../types'
import { IDriver, TModalContent } from './types'
import { IOrder } from '../Orders/types'

import { ordersDummy } from '../Orders/data' // waiting for API



const DetailPage: React.FC = () => {

  const { id } = useParams()

  const { token } = useContext(Contexts.AuthContext)

  const { loading, request } = useHttp()

  const [driver, setDriver] = useState<IDriver | null>(null)
  const [orders, setOrders] = useState<IOrder[]>([])
  const [modal, setModal] = useState<TModalContent>(null)
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 5,
    totalCount: 0
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: 'date',
    sortOrder: 'asc',
    orderNumber: '',
  })
  const history = useHistory();
  console.log("history.location",history.location);

  let address: any = []
  let prefix: string = ''

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => { console.log('jfnweoifnew');
      const sortBy = e.target.value.split('-')[0]
      const sortOrder = e.target.value.split('-')[1]
      setFilter({ ...filter, sortBy, sortOrder })
      Callbacks.FetchOrders()
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') Callbacks.FetchOrders()
    },
    searchHandler: () => {
      Callbacks.FetchOrders()
    },
    fineHandler: () => {
      setModal('fine')
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    deleteHandler: () => {
      const response = window.confirm('Delete?')
      if (response) Callbacks.Delete()
    },
    unblockHandler: () => {
      const response = window.confirm('Unblock?');
      if (response) Callbacks.UnBlock()
    },
    blockHandler: () => {
      const response = window.confirm('Block?')
      if (response) Callbacks.Block()
    },
    clearModal: () => {
      setModal(null)
      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    }
  }

  const Callbacks = {
    FetchDriver: async () => {
      try {
        const response: any = await request(
          `${config.API}/drivers/${id}`, 'GET', null, {
          Authorization: token
        })

        setDriver(response.data)
      } catch (e) {
        console.log(e)
      }
    },
    FetchOrders: async (page?: number) => {
      try {
        const response: any = await request(
            `${config.API}/drivers/${id}/byDriver/?page=${page ? page : pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}&_index=${filter.orderNumber}`,
            'GET', null, {
              Authorization: token
            })

        if (response.data) {
          response.data.forEach((item: any, key: number) => {
            item.time = new Date(item.time).toLocaleTimeString() + ' ' + new Date(item.time).toLocaleDateString();
            address[key] = '';
            if (item.status === 'searchDriver') {
              item.status = 'Поиск водителя';
            } else if (item.status === 'accepted') {
              item.status = 'Подтвержден';
            } else if (item.status === 'driverInGoing') {
              item.status = 'Водитель в пути';
            } else if (item.status === 'inProgress') {
              item.status = 'В процессе';
            } else if (item.status === 'waiting_for_payment') {
              item.status = 'Ожидает оплаты';
            } else if (item.status === 'done') {
              item.status = 'Выполнен';
            } else if (item.status === 'canceledByClient') {
              item.status = 'Отменен клиентом';
            } else if (item.status === 'canceledByAdmin') {
              item.status = 'Отменен администратором';
            } else if (item.status === 'paymentError') {
              item.status = 'Ошибка оплаты';
            } else if (item.status === 'paymentProcessing') {
              item.status = 'В процессе оплаты';
            } else if (item.status === 'expiredByTime') {
              item.status = 'Истек';
            }

            if (item.typeOfPayment === 'online') {
              item.typeOfPayment = 'Карта';
            } else {
              item.typeOfPayment = 'Наличные';
            }

            item.coordinates.forEach((item1: any, key1: number) => {
              prefix = (key1 > 0) ? '->' : '';
              address[key] += prefix + item1.displayTitle;
            })
            item.distance = address[key]
          })

          setOrders(response.data)

          if (response.pagination) setPagination({ ...pagination, totalCount: response.pagination.total, page: response.pagination.page })
          else setPagination({ ...pagination, totalCount: 0 })
        // setOrders(ordersDummy)
        } else {
          history.push('/perm_error');
        }


      } catch (e) {
        console.log(e)
      }
    },
    UnBlock: async () => {
      try {
        await request(
            `${config.API}/drivers/unblock/${id}`, 'POST', null, {
              Authorization: token
            })
       await Callbacks.FetchDriver()
      } catch (e) {
        console.log(e)
      }
    },
    Block: async () => {
      try {
        await request(
            `${config.API}/drivers/ban/${id}`, 'POST', null, {
              Authorization: token
            })
       await Callbacks.FetchDriver()
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(
            `${config.API}/drivers/${id}`, 'DELETE', null, {
              Authorization: token
            })
        history.push('/drivers');
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.FetchDriver()
    Callbacks.FetchOrders()
  }, [filter.sortBy, filter.sortOrder, pagination.page])


  if (loading) {
    return (
      <Page.Preloader />
    )
  }


  return (
    <Fragment>

      <Components.Modal
        type={modal}
        data={driver}
        clearModal={Events.clearModal}
      />

      <Page.BackLink
        title='Вернуться к списку водителей'
        linkTo='/drivers'
      />

      <div className='page-wrapper'>
        <Components.DetailBlock
          data={driver}
          fineHandler={Events.fineHandler}
          blockHandler={driver?.status !== 'blocked' ? Events.blockHandler : Events.unblockHandler}
          deleteHandler={Events.deleteHandler}
        />

        <Page.Filter
          dateSelect
          statusSelect
          tariffSelect
          orderInput
          form={filter}
          events={Events}
        >

          <Form.DetailField
            type='filter'
            label='Заказов'
            value={`${pagination.totalCount}`}
          />

        </Page.Filter>

        { (orders[0]) ?
            <div><Table.OrdersTable
                items={orders}
                padding='bottom'
            /> <Page.Pagination
                linkTo={`/drivers/${id}`}
                pagination={pagination}
                paginateHandler={Events.paginateHandler}
            /> </div> : <div className="havenot-order">У этого водителя нет заказов!</div> }

        <Page.Footer
          linkTitle={['История вывода/пополнения средств', 'История штрафов']}
          linkTo={[`/drivers/history/${id}`, `/drivers/fines/${id}`]}
          data={driver?.ordersSum}
        />

      </div>
    </Fragment>
  )
}

export default DetailPage
