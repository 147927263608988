import React, {useState, useEffect, useContext} from 'react'

import * as Table from '../../../../components/Table'
import * as Form from '../../../../components/Form'

import { config } from '../../../../config/config'

import { IUser } from '../../types'
import * as Contexts from "../../../../contexts";
import {useHttp} from "../../../../hooks/http.hook";


const NewUser: React.FC = () => {

  const { token } = useContext(Contexts.AuthContext);

  const { request } = useHttp()

  const [isCorrect, toggleCorrect] = useState({
    title: true,
    description: true,
    login: true,
    password: true,
    email: true
  })
  const [form, setForm] = useState<IUser | any>({
    title: '',
    description: '',
    login: '',
    password: '',
    email: ''
  })

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: () => {
      Callbacks.Create()
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
         await request(`${config.API}/users`, 'POST', form, {
             Authorization: token
         })

        await window.location.reload(false)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (form.title.length > 0) toggleCorrect({ ...isCorrect, title: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.title])

  useEffect(() => {
    if (form.description.length > 0) toggleCorrect({ ...isCorrect, description: true })
    else toggleCorrect({ ...isCorrect, title: false })
  }, [form.description])

  useEffect(() => {
    if ((form.login as string).length > 0) toggleCorrect({ ...isCorrect, login: true })
    else toggleCorrect({ ...isCorrect, login: false })
  }, [form.login])

  useEffect(() => {
    if ((form.password as string).length > 0) toggleCorrect({ ...isCorrect, password: true })
    else toggleCorrect({ ...isCorrect, password: false })
  }, [form.password])

    useEffect(() => {
        if ((form.email as string).length > 0) toggleCorrect({ ...isCorrect, email: true })
        else toggleCorrect({ ...isCorrect, email: false })
    }, [form.email])


  return (
    <div className={`table-track table-track--users`}>

      <Table.CustomCell>
        <Form.Input
          name='title'
          value={(form.title as string)}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          name='login'
          value={(form.login as string)}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

      <Table.CustomCell>
        <Form.Input
          name='password'
          value={(form.password as string)}
          inputHandler={Events.inputHandler}
        />
      </Table.CustomCell>

        <Table.CustomCell>
            <Form.Input
                name='email'
                value={(form.email as string)}
                inputHandler={Events.inputHandler}
            />
        </Table.CustomCell>

        <Table.CustomCell>
            <Form.Textarea
                name='description'
                value={(form.description as string)}
                inputHandler={Events.inputHandler}
            />
        </Table.CustomCell>

      <Table.Cell
        text='0'
        direction='column'
      />

      <Table.Cell
        text='0'
        direction='column'
      />

      <Table.Cell
        type='button'
        buttonTitle='Создать'
        buttonType='default'
        buttonHandler={Events.createHandler}
      />

    </div>
  )
}

export default NewUser
