import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const IndexPage = () => (
    <body className="not-found">
    < div className='c'>
        < div className='_404'> 403 </div>
        <hr />
        <div className='_1'>Извините,</div>
        <div className='_2'>но у вас нет доступа к этому разделу</div>
        <Link className="btn_back" to="/">Вернутся назад</Link>
    </div>
    </body>
);

export default IndexPage
