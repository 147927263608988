import React from 'react'

import { useHistory } from 'react-router-dom'

import Cell from '../../../../components/Table/Cell'

import { ITrackProps } from './types'


const Track: React.FC<ITrackProps> = ({
  type,
  data
}) => {
   // console.log(data.cars[0].status);

  const history = useHistory()

  const Events = {
    toProfile: () => {
      history.push(`/drivers/${data._id}`)
    },
    toProfileFromParent: () => {
      history.push(`/${data._id}`)
    }
  }

  return (
    <div className={`table-track table-track--${type}`}>

      <Cell
        status={data.cars[0]?.status}
        textWeight='bold'
        text={data.fullName}
      />

      <Cell
        status={data.cars[0]?.status}
        text={new Date(data.createdAt).toLocaleDateString()}
      />

      <Cell
        status={data.cars[0]?.status}
        textWeight='bold'
        text={data.phone}
      />

      <Cell
        status={data.cars[0]?.status}
        textWeight='bold'
        text={(data.ordersCount !== undefined) ? `${data.ordersCount}` : 'Нет заказов'}
      />

      <Cell
        status={data.cars[0]?.status}
        text={data.status}
      />

        <Cell
            status={data.cars[0]?.status}
            text={(data?.total) ? `Всего: ${data?.total} грн` : `Всего: 0 грн`}
            modal
            modalText={
                `Наличкой: ${(data?.cash) ? data?.cash : 0} грн
             Онлайн: ${(data?.onlineSum) ? data?.onlineSum : 0} грн`
            }
        />

        <Cell
            status={data.cars[0]?.status}
            text={'' + data.commission}
        />

      <Cell
        type='cars'
        status={data.cars[0]?.status}
        textWeight='bold'
        cars={data.cars}
      />

        <Cell
            text={data?.company?.title ? data?.company?.title : 'f1 cargo'}
            style={{
                alignItems: 'flex-start',
            }}
        />

     <Cell
        text='Cell for documents'
        status={data.cars[0]?.status}
        type='documents'
        cars={data.cars}
        textWeight='bold'
      />

      <Cell
        status={data.cars[0]?.status}
        text={`${data.balance}`}
      />

      <Cell
        type='button'
        status={data.cars[0]?.status}
        reason={'Smth'}
        buttonTitle={'../../assets/baseline_account.png'}
        buttonType='default'
        buttonHandler={Events.toProfile}
      />

    </div>
  )
}

export default Track
