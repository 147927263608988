import React from 'react'

import * as Form from '../../components/Form'

import { IInputCellProps } from './types'



const InputCell: React.FC<IInputCellProps> = ({
  name,
  value,
  isCorrect,
  changeHandler
}) => {

  return (
    <div className='table-cell table-cell--top'>
      <Form.InputToggle
        type='text'
        name={name}
        isCorrect={isCorrect}
        value={value}
        changeHandler={changeHandler}
      />
    </div>
  )
}
export default InputCell