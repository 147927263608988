import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'

import { IPerson } from './types'

import { config } from '../../config/config'

import { cards, currentPerson } from './data'
import {useHistory} from "react-router-dom"; // waiting for API



const IndexPage: React.FC = () => {

    const { token } = useContext(Contexts.AuthContext)

    const { loading, request } = useHttp()

    const [people, setPeople] = useState<IPerson[]>([])
    const [isCreate, toggleCreate] = useState<boolean>(false)
    const [person, setPerson] = useState<IPerson>({
        _id: '',
        login: '',
        notif: false
    })
    const history = useHistory();

    const Events = {
        addHandler: () => {
            toggleCreate(!isCreate)
        }
    }

    const Callbacks = {
        FetchPeople: async () => {
            try {
                  const response: any = await request(`${config.API}/settings`, 'GET', null, {
                    Authorization: token
                  });

                  if (!response.data) {
                      history.push('/perm_error');
                  }

                  setPeople(response.data)

               //  setPeople(cards)
            } catch (e) {
                console.log(e)
            }
        },
        FetchPerson: async () => {
            try {
                 const response: any = await request(`${config.API}/profile`, 'GET', null, {
                     Authorization: token
                 })

                // console.log(response);

               // response.user.notif = true;
                setPerson(response.user)

                // setPerson(currentPerson)
            } catch (e) {
                console.log(e)
            }
        },
    }

    useEffect(() => {
        Callbacks.FetchPeople()
        Callbacks.FetchPerson()
    }, [])


    if (loading) {
        return (
            <Page.Preloader />
        )
    }


    return (
        <div className='page-wrapper'>
            <Components.Header
                data={person}
                addHandler={Events.addHandler}
            />

            <Components.Table
                items={people}
                isCreate={isCreate}
            />
        </div>
    )
}

export default IndexPage
