import React, { useContext, useState, ChangeEvent, useEffect } from "react";

import * as Contexts from "../../../../contexts";
import * as Table from "../../../../components/Table";

import Cell from "../../../../components/Table/Cell";
import CustomCell from "./CustomCell";
import ImageCell from "./ImageCell";

import { ITrackProps } from "./types";
import { TTariffForm } from "../../types";
import { config } from "../../../../config/config";
import { useHttp } from "../../../../hooks/http.hook";

const Track: React.FC<ITrackProps> = ({ type, data }) => {
  const { services } = useContext(Contexts.ConfigContext)!;
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [form, setForm] = useState<TTariffForm | null>(null);

  const Events = {
    changeHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const target = e.target;
      const name = e.target.name.split("-")[0];
      const subname = e.target.name.split("-")[1];
      console.log("target.name", target.name);

      if (subname) {
        console.log(name, subname, +target.value);
        //@ts-ignore
        console.log(form[name][subname]);
        if (target.name.split("-")[1] === "min") {
          // @ts-ignore
          if (+target.value >= form[name]["max"]) {
            // @ts-ignore
            target.value = form[name]["min"];
          }
        } else {
          // @ts-ignore
          if (+target.value < form[name]["min"]) {
            // @ts-ignore
            target.value = form[name]["max"];
          }
        }
        // @ts-ignore
        if (+target.value > 0 && form[name]["min"] <= form[name]["max"])
          setForm({
            ...form!,
            [name]: {
              //@ts-ignore
              ...form[name],
              [subname]: +target.value,
            },
          });
      } else {
        if (+target.value < 1) {
          // @ts-ignore
          target.value = 1;
        }
        if (target.type === "file") {
          //@ts-ignore
          setForm({ ...form, image: target.files[0] });
        } else {
          setForm({ ...form!, [target.name]: target.value });
        }
      }
    },
    deleteHandler: () => {
      const response = window.confirm("Delete?");
      if (response) Callbacks.Delete();
    },
    saveHandler: () => {
      const response = window.confirm("Save?");
      if (response) Callbacks.Save();
    },
  };

  const Callbacks = {
    Delete: async () => {
      try {
        const response: any = await request(
          `${config.API}/tariffs/${data._id}`,
          "DELETE",
          null,
          {
            Authorization: token,
          }
        );
        if (response) await window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
    Save: async () => {
      try {
        let image: string = "";

        if (form?.image) {
          const data = new FormData();
          data.append("file", form.image);
          const response_image: any = await request(
            `${services.image_server_api}/api/images/upload/public`,
            "POST",
            data,
            {
              Authorization: token,
            }
          );
          image = response_image.endpoint;
        }

        const sendData = {
          ...(form?.image ? { ...form, image } : { ...form }),
        };

        const response: any = await request(
          `${config.API}/tariffs/${data._id}`,
          "POST",
          { ...sendData },
          {
            Authorization: token,
          }
        );

        if (response.status) await window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    setForm({
      title: data.title,
      hidden: data.hidden,
      pricePerHour: data.pricePerHour,
      minOrdersHours: data.minOrdersHours,
      widthRange: { ...data.widthRange },
      weightRange: { ...data.weightRange },
      lengthRange: { ...data.lengthRange },
      heightRange: { ...data.heightRange },
      capacityRange: { ...data.capacityRange },
    });
  }, []);

  if (!form) return null;

  return (
    <div className={`table-track table-track--${type}`}>
      <Table.MainCell
        name="title"
        link={{
          to: `/tariffs/${data._id}/history`,
          title: "История",
        }}
        value={form.title}
        events={Events}
      />

      <ImageCell src={data.image!} changeHandler={Events.changeHandler} />

      <CustomCell
        type="dimensions"
        dimensionsType="from"
        form={form}
        changeHandler={Events.changeHandler}
      />

      <CustomCell
        type="dimensions"
        dimensionsType="to"
        form={form}
        changeHandler={Events.changeHandler}
      />

      <CustomCell
        inputType="number"
        name="minOrdersHours"
        suffix="час"
        value={form.minOrdersHours}
        changeHandler={Events.changeHandler}
      />

      <CustomCell
        inputType="number"
        name="pricePerHour"
        suffix="грн"
        value={form.pricePerHour}
        changeHandler={Events.changeHandler}
      />

      <Cell text={`${data.carsCount}`} textWeight="bold" direction="column" />
    </div>
  );
};

export default Track;
