import React, { useContext, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Components from "./components";
import * as CSV from "../../components/ExcelUpload";

import { useHttp } from "../../hooks/http.hook";

import { IPagination, IFilter } from "../../types";
import { IFinance, ISum } from "./types";

import { FinancesDummy } from "./data"; // waiting for API
import { config } from "../../config/config";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);

  const { loading, request } = useHttp();

  const [finances, setFinances] = useState<IFinance[]>([]);
  const [xslsData, setXSLSData] = useState<any>();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "fullName",
    sortOrder: "asc",
    fullName: "",
    phone: "",
    dateUse: false,
    dateFrom: "",
    dateTo: "",
  });
  const [sumResults, setSumResults] = useState<ISum>({
    totalOnlineSum: 0,
    totalCashSum: 0,
    totalSum: 0,
  });
  const history = useHistory();

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    },
    selectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => {
      const sortBy = e.target.value.split("-")[0];
      const sortOrder = e.target.value.split("-")[1];
      setPagination({ ...pagination, page: 1 });
      setFilter({ ...filter, sortBy, sortOrder });
    },
    timeHandler: () => {
      setFilter({ ...filter, dateUse: !filter.dateUse });
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
  };

  const Callbacks = {
    FetchFinances: async () => {
      try {
        const response: any = await request(
          `${config.ordersAPI}/finances?page=${
            filter.dateUse
              ? filter.dateFrom
              : "" !== "" && filter.dateTo !== ""
              ? (pagination.page = 1)
              : pagination.page
          }&perPage=${pagination.perPage}&sortOrder=${
            filter.sortOrder
          }&sortBy=${filter.sortBy}&createdAt[]=${
            filter.dateUse ? filter.dateFrom : ""
          }&createdAt[]=${filter.dateUse ? filter.dateTo : ""}`,
          "GET",
          null,
          {
            Authorization: token,
          }
        );

        const responseSXLS: any = await request(
          `${config.ordersAPI}/finances?page=${1}&perPage=${
            response?.pagination?.total
          }&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}&createdAt[]=${
            filter.dateUse ? filter.dateFrom : ""
          }&createdAt[]=${filter.dateUse ? filter.dateTo : ""}`,
          "GET",
          null,
          {
            Authorization: token as string,
          }
        );

        const Arr: any = [];

        if (!response.data) {
          history.push("/perm_error");
        } else {
          response.data.forEach((item: any) => {
            if (item.event === "deposit") {
              item.event = "Депозит";
            } else if (item.event === "withdrawal") {
              item.event = "Вывод средств";
            } else if (item.event === "orderPayment") {
              item.event = "Оплата заказа";
            } else if (item.event === "commission") {
              item.event = "Комиссия";
            } else if (item.event === "fine") {
              item.event = "Штраф";
            }

            if (item.status === "new") {
              item.status = "Новый";
            } else if (item.status === "successful") {
              item.status = "Успешный";
            } else if (item.status === "paymentProcessing") {
              item.status = "В процессе оплаты";
            }
          });

          responseSXLS.data.forEach((resp: any) => {
            if (resp.event === "deposit") {
              resp.event = "Депозит";
            } else if (resp.event === "withdrawal") {
              resp.event = "Вывод средств";
            } else if (resp.event === "orderPayment") {
              resp.event = "Оплата заказа";
            } else if (resp.event === "commission") {
              resp.event = "Комиссия";
            } else if (resp.event === "fine") {
              resp.event = "Штраф";
            }

            if (resp.status === "new") {
              resp.status = "Новый";
            } else if (resp.status === "successful") {
              resp.status = "Успешный";
            } else if (resp.status === "paymentProcessing") {
              resp.status = "В процессе оплаты";
            }

            Arr.push({
              date: new Date(resp.createdAt).toLocaleDateString(),
              status: resp.status,
              event: resp.event,
              action: resp.action === "plus" ? "Начисление" : "Списание",
              sum: resp.sum,
            });
          });
          Arr.push({
            totalSum: responseSXLS.totalSum,
            cashTotalSum: responseSXLS.totalCashSum,
            onlineTotalSum: responseSXLS.totalOnlineSum,
          });
        }

        setXSLSData(Arr);
        setFinances(response.data);
        setSumResults({
          totalOnlineSum: response.totalOnlineSum,
          totalCashSum: response.totalCashSum,
          totalSum: response.totalSum,
        });
        // setFinances(FinancesDummy)
        if (response.pagination)
          setPagination({
            ...pagination,
            totalCount: response.pagination.total,
            page: response.pagination.page,
          });
        else setPagination({ ...pagination, totalCount: 0 });
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.FetchFinances();
  }, [
    filter.sortBy,
    filter.sortOrder,
    pagination.page,
    filter.dateUse /*filter.dateFrom, filter.dateTo*/,
  ]);

  if (loading) {
    return <Page.Preloader />;
  }

  return (
    <div className="page-wrapper">
      <Page.Filter dateSelect agePicker form={filter} events={Events}>
        <Form.DetailField
          type="filter"
          label="Заказов выполнено на сумму"
          value={`${sumResults.totalSum} грн`}
        />

        <Form.DetailField
          type="filter"
          label="Оплачено наличными"
          value={`${sumResults.totalCashSum} грн`}
        />

        <Form.DetailField
          type="filter"
          label="Оплачено картой"
          value={`${sumResults.totalOnlineSum} грн`}
        />

        <CSV.ExcelUpload csvData={xslsData} fileName={"finance"} />
      </Page.Filter>

      <Components.Table items={finances} />

      <Page.Pagination
        linkTo="/finance"
        pagination={pagination}
        paginateHandler={Events.paginateHandler}
      />
    </div>
  );
};

export default IndexPage;
