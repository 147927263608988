import React, { useState, useEffect, Fragment } from 'react'

import { ICheckboxesProps } from './types'
import { Translate } from './translate'


const Checkboxes: React.FC<ICheckboxesProps> = ({
  id,
  items,
  inputHandler
}) => {

  const [checkboxes, setCheckboxes] = useState<any[]>([])


  useEffect(() => {
    let checkboxesInitial = []

    for (let key in items) {
      let item = {
        name: key,
        //@ts-ignore
        value: items[key]
      }
      checkboxesInitial.push(item)
    }

    setCheckboxes(checkboxesInitial)
  }, [items])

  return (
    <Fragment>
      {checkboxes.map(checkbox => (
        <label key={checkbox.name} className='label' htmlFor={checkbox.name + id}>
          <input
            name={checkbox.name}
            className='checkbox'
            type='checkbox'
            id={checkbox.name + id}
            checked={checkbox.value}
            onChange={inputHandler}
          />
          {
            //@ts-ignore
            Translate[checkbox.name]
          }
        </label>
      ))}
    </Fragment>
  )
}

export default Checkboxes
